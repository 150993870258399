div#app {
  height: 100%;
  font-family: @sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
}

* {
  box-sizing: border-box;
}

.fluid {
  width: 100%;
  height: auto;
}

.fluid-y {
  height: 100%;
}

.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.pointer {
  cursor: pointer;
}
