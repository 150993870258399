#form__Dichiarazioni {
  .ui.grid {
    div.row {
      &:first-child {
        img.ui.image {
          width: 20px;
          position:absolute;
          top: 0;
          right: 1rem;
        }
      }
    }
  }
}
