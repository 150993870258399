#form__Verifica_GeneraDocumento {
  .ui.grid {
    .row {
      &:nth-child(2) {
        .ui.image {
          width: 20px;
          position: absolute;
          top: 0;
          right: 1rem;
        }
      }
    }
    .column {
      &.document-column {
        .disabled.field {
          opacity: 1;
          .ui.disabled.dropdown {
            opacity: 1;
          }
          i {
            opacity: 0;
          }
        }
      }
    }
  }
}
