#app {
  .standardlayout__container {
    min-height: 100vh;
    padding: 6.25rem 0 0 5rem;
    background: @c-secondary url('../../images/background-access.svg') no-repeat center center;
    background-size: cover;

    @media screen and (max-width: @bp-md-mx) {
      padding: 4.75rem 0 4.75rem 3.25rem;
    }
  }
}