#app {
  .agent-container {
    .ui.grid {
      .column {
        &.columnProfile {
          justify-content: flex-start;
        }
      }
    }
  }

  .agentSectionBody {
    max-width: 90rem;
    margin: 0 auto;
    padding: 2.5rem 2.5rem 4rem;

    @media (max-width: @bp-2xl-mx) {
      max-width: 70rem;
    }

    @media screen and (max-width: @bp-md-mx) {
      padding: 2.5rem 1rem 4rem;
    }

    .contentGrid {
      table {
        tr {
          td {
            .ctas {
              display: flex;
              justify-content: center;
              > a {
                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: @bp-md-mx) {
        overflow-x: scroll;
        padding-bottom: 2rem;
      }
    }
  }

  #agentProfileForm {
    .field {
      width: 100%;
    }
    .ui.grid.company-type {
      .ui.buttons {
        flex: 0 0 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 2rem;
        @media screen and (max-width: @bp-md-mx) {
          grid-template-columns: repeat(1, 1fr);
        }
        button {
          padding: 1.125rem 2rem !important;
          background: transparent;
          border: 1px solid @c-grey-light;
          border-radius: 10px;
          font-size: 1.05rem !important;
          font-weight: 500;
          line-height: 1.2;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;

          @media screen and (max-width: @bp-md-mx) {
            margin-bottom: 1rem;
          }

          i.icon {
            margin: 0 0 1rem 0;
            font-size: @font-32;
          }

          &.primary {
            border: 2px solid @c-primary;
            color: #000;
            font-weight: 600;
            background-color: rgba(128, 152, 178, 0.05);

            i.icon {
              color: @c-primary;
            }
          }
        }
      }
    }
  }

  .agentSectionActions {
    padding-top: 1.5rem;
    float: right;
  }
}

#agentProfileForm {
  .ui.buttons button:first-child {
    border: 0px solid transparent;
  }

  .ui.buttons.create .button {
    border-radius: 50rem;
  }

  .ui.grid.company-type {
    .row {
      &:last-child {
        .column {
          display: flex;
          justify-content: flex-end;
          button {
            min-width: 8rem;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
