.ui.page.modals {
  background-color: rgba(0, 0, 0, 0.25);

  > div {
    position: relative;
  }

  .modal {
    box-shadow: none;

    .close.icon {
      margin: 0;
      padding: 0;
      font-size: 0;
      background: url('/src/assets/images/icon/close.svg') no-repeat center
        center;
      background-size: 50%;
      top: 0.5rem;
      right: 0.5rem;
    }

    > div.header {
      color: @c-primary;
      font-weight: 500;
      font-size: @font-24;
      background-color: transparent;
      border-bottom: none;
      .tooltip--left span {
        padding-right: 1.5rem;
        font-weight: 700;
        color: @c-grey-dark;
      }
    }

    .content {
      padding: 2.5rem;
      @media screen and (max-width: 769px) {
        padding: 1rem !important;
      }

      h3.header {
        font-size: @font-24;
        font-weight: 500;
      }

      a {
        color: @c-primary;
      }
    }

    > div.noPaddingTop.content {
      padding-top: 0;
    }

    .actions {
      padding: 1rem !important;
      button {
        margin: 0 0 0 1rem;
      }
    }
  }

  @media screen and (max-width: 769px) {
    padding: 1rem !important;
    > div {
      width: 100%;
    }
  }

  /** Modal Request */

  .modal__requestHeader {
    padding: 1.25rem 2.5rem;

    @media screen and (max-width: @bp-md-mx) {
      padding: 1.5rem 1rem !important;
    }

    .steps .step {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 1.125rem;
      line-height: 1.2;
      padding: 1rem 2rem;

      @media screen and (max-width: @bp-md-mx) {
        flex-direction: row;
        align-items: center;
      }

      .icon {
        font-size: 1.5rem;

        @media screen and (max-width: @bp-md-mx) {
          margin: 0 0.5rem 0 0;
        }
      }

      span {
        margin-top: 0.75rem;

        @media screen and (max-width: @bp-md-mx) {
          margin: 0;
        }
      }

      &.active {
        background: @c-grey-extra;
        font-weight: 600;

        .icon {
          color: @c-primary;
        }
      }

      &.completed .icon:before {
        color: @c-success;
      }
    }
  }

  [class*='appointment_modal_actions'] {
    padding: 1.25rem 2.5rem;

    button:last-child {
      background: @c-success;
      color: #fff;
    }
  }

  .ui.error.input {
    input[type='text'] {
      color: #9f3a38;
      background: #fff6f6;
    }
  }

  label.error {
    color: #9f3a38;
  }

  span.error {
    color: #eb5757;
  }
}

/** Legal representative modal */

.ui.page.modals {
  @media screen and (max-width: 769px) {
    .actions {
      .field {
        display: flex;
        button[form='legal-representative'],
        button[form='group-structure'] {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }
}
