#app {
  .tabular.menu {
    border: 0;
  
    .item {
      border: 0;
      border-radius: .5rem .5rem 0 0 !important;
      padding: .625rem 1.5rem;
      font-size: @font-14;
      font-weight: 500;
      margin-right: .875rem;
      background: #FFF;
  
      &:not(.active) {
        opacity: .65;
      }
    }
  }
  
  .active.tab {
    border: 0;
    border-radius: 0 .5rem .5rem .5rem;
    padding: 1.5rem;

    @media screen and (max-width: @bp-md-mx) {
      padding: 1rem;
    }
  
    h3 {
      font-size: @font-22;
      font-weight: 500;
    }
  }
}