.form__BalanceCalcoloDimensionaleAzienda {
  .balance_calcolo_dimesionale_azienda__content {
    div.column {
      > img.ui.image {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
      }
    }
  }
}
