table.ui.table {
  margin: 0;
  background: transparent;
  border: 0;
  border-collapse: collapse;

  thead th {
    background: @c-grey-norm;
    color: #fff;
    padding: 0.75rem 2rem;
    font-size: @font-14;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;

    @media screen and (max-width: @bp-md-mx) {
      padding: .5rem 1rem;
    }

    div label {
      background: @c-grey-norm;
      color: #fff !important;
      font-size: @font-14;
      font-weight: 300;
      text-transform: uppercase;
      text-align: center;
    }
  }

  tbody {
    background: #fff;

    td {
      padding: 1.5rem 2.5rem;

      @media screen and (max-width: @bp-md-mx) {
        padding: 1rem 1.5rem;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 0.5rem;
      }

      td:last-child {
        border-bottom-right-radius: 0.5rem;
      }
    }
  }

  tfoot {
    th {
      background: transparent;
      padding: 2rem 0 0;
      border: 0;
    }

    .ui.pagination {
      background: transparent;
      border: none;
      box-shadow: none;

      .item {
        margin-left: 0.5rem;
        border-radius: 10rem;
        display: inline-block;
        background: #fff;
        border: 1px solid @c-grey-light;

        &:before {
          content: none;
        }

        &:hover {
          background: @c-grey-norm;
          color: #fff;
        }
      }
    }
  }
}
