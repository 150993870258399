#app .request {
  #form__Piano {
    // CARD
    .ui.card {
      width: 26rem;
      max-width: 50%;
      margin: 0 1.25rem;
      border: 2px solid @c-primary;
      border-radius: 2rem;
      box-shadow: none;

      &.request__plan_card_selected {
        outline: 3px solid @c-primary;
      }

      @media screen and (max-width: @bp-md-mx) {
        max-width: 85vw;

        &:first-child {
          margin-bottom: 5rem;
        }
      }

      > .content {
        border: 0;
        padding-right: 2.5rem;
        padding-left: 2.5rem;

        @media screen and (max-width: @bp-md-mx) {
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        }
      }

      .content__header {
        padding-top: 2.5rem;
        padding-bottom: 0.75rem;

        .header {
          font-size: @font-36;
          font-weight: 600;

          @media screen and (max-width: @bp-md-mx) {
            font-size: @font-32;
          }
        }

        .content__image-wrapper {
          position: absolute;
          width: 7rem;
          height: 7rem;
          right: 2.5rem;
          top: -2.5rem;
          filter: drop-shadow(0.25rem 0.25rem 0.75rem rgba(61, 61, 61, 0.24));

          @media screen and (max-width: @bp-md-mx) {
            width: 6rem;
            height: 6rem;
            top: -3.5rem;
            right: 1rem;
          }

          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }

      .content__price {
        font-size: @font-18;
        font-weight: 600;
        padding-top: 2.5rem;
        padding-bottom: 2rem;

        @media screen and (max-width: @bp-md-mx) {
          padding-top: 1.5rem;
        }

        strong {
          font-size: @font-36;
        }
      }

      .content__features {
        .item {
          padding-bottom: 0.75rem;
          display: flex;
          align-items: center;

          i:before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 0.875rem;
          }

          i.green.check:before {
            background: url('/src/assets/images/icon/check.svg') no-repeat
              center center !important;
            background-size: contain;
          }

          i.red.cancel:before {
            background: url('/src/assets/images/icon/close-red.svg') no-repeat
              center center !important;
            background-size: contain;
          }

          .content {
            font-weight: 500;
            padding-left: 0.75rem;
            line-height: 1.5;

            @media screen and (max-width: @bp-md-mx) {
              text-align: left;
            }
          }

          i.red.cancel + .content {
            color: @c-grey-mid;
            text-decoration: line-through;
          }
        }
      }

      .content__buttons {
        padding-bottom: 2.5rem;

        button {
          width: 100%;
        }
      }
    }

    // INVOICE WRAPPER
    .request__invoice-wrapper {
      width: 100%;
      max-width: 64rem;
      margin: 0 auto;
      padding: 3rem 0;

      .request__invoice-checkbox {
        text-align: center;
        margin-bottom: 2.5rem;
      }

      .column {
        padding: 0 1rem;
      }

      > .row.three {
        display: flex;
        flex-wrap: wrap;

        > .column {
          width: calc(100% / 3) !important;

          @media screen and (max-width: @bp-md-mx) {
            width: 100% !important;
          }
        }
      }
    }
  }
  #form__Pre_Pagamento {
    //VOUCHER CODE INPUT
    .request__voucher-code {
      width: 70% !important;
    }
  }
}
