.appointment_modal_actions {
    display: flex;
    justify-content: flex-end;
}
.appointment_help {
    display: flex;
    height: 40px;
    font-size: @font-14;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;

    img {
        display: inline-block;
        margin-right: .5rem;
    }
}

.appointment_title {
    font-size: @font-18;
    font-weight: 500;
}

.appointment_legend {
    list-style: none;
    width: 20em;
    padding-left: 1rem;
    margin: .75rem 0;

    &__box {
        width: 1em;
        height: 1em;
        border: 1px solid black;
        float: left;

        &--busy {
            background-color: red;
        }

        &--available {
            background-color: @c-success;
        }

        &--not-available {
            background-color: grey;
        }

        &--selected {
            background-color: @c-primary-dark;
        }
    }

    &__color {
        font-weight: bold;
    }

    li {
        margin-right: 10px;
        font-size: @font-14;
        display: flex;
        align-items: center;

        span {
            margin-left: 4px;
        }
    
    }
}

.fc-highlight {
    background-color: @c-primary-dark !important;
}