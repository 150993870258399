#form__Dichiarazioni_De_Minimis {
  .ui.grid {
    .row {
      display: flex;
      .column.decl--checkbox {
        flex: 0 0 5rem;
        width: 5rem;
      }
      .column.decl--specifications {
        flex: 1;
        &.disabled {
          opacity: 0.5;
        }
        p {
          line-height: 1.8rem;
          font-size: 1rem;
          text-align: justify;
          text-justify: inter-word;
        }
        .field {
          border: none;
          display: inline;
          width: fit-content;
          flex: 0 0 fit-content;
          height: 100%;
          margin: 0;
          font-size: 1rem;
          padding: 0;
          .ui.input {
            border: none;
            width: fit-content;
            flex: 0 0 fit-content;
            min-width: 2rem;
            height: 100%;
            padding: 0;
            margin: 0;
            &.error {
              input {
                background: #fff6f6;
                border-color: #e0b4b4;
                color: #9f3a38;
              }
            }
            input {
              width: fit-content;
              flex: 0 0 fit-content;
              min-width: 2rem;
              padding: 0.2rem 0.5rem !important;
              line-height: 1.2rem;
            }
            &.decree-letter {
              input {
                min-width: 2rem;
                width: 2rem;
                flex: 0 0 2rem;
                padding: 0.2rem 0.5rem 0.2rem 0.6rem !important;
              }
            }
            &.reimbursementDate {
              margin: 0 3px;
            }
            .MuiFormControl-root {
              width: fit-content;
              flex: 0 0 fit-content;
              .MuiInputBase-root {
                max-width: fit-content;
                border-radius: 0.25rem;
              }
              input.MuiInputBase-input {
                min-width: 8rem;
                width: 8rem;
                flex: 0 0 8rem;
                height: auto;
                margin: 0;
              }
              .MuiIconButton-root {
                padding: 0;
                margin-right: 0.3rem;
              }
            }
          }
        }
      }
    }
  }
}
