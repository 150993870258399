.request__sidebar {
  background-color: #f1ece3;

  .ui.image.header {
    margin-left: 0.5rem;
  }

  &__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    padding: 20px;
  }
}

.ui.card.request__sidebar__card {
  margin: auto;
  border-radius: 8px;
  margin-bottom: 2rem;

  .content.request__sidebar__card__content {
    display: flex;
    justify-content: space-between;

    &&--tutorial {
      flex-direction: column;
    }
  }

  .request__sidebar__card__button {
    padding: 0.8rem !important;
    background-color: #8098b2;
    border-radius: 30px;
    margin-left: 0.5rem;

    .plus.icon {
      color: #ffffff;
    }
  }
}

.ui.card.request__maintenance__card {
  position: relative;
  border-radius: 8px;
  margin: 4rem 0 0 0;
  width: 100%;
  max-width: 450px;
  min-height: unset;

  .content.request__maintenance__card__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    &::after {
      position: absolute;
    }
  }

  .request__maintenance__card__button {
    padding: 0.8rem !important;
    background-color: #8098b2;
    border-radius: 30px;
    margin-left: 0.5rem;

    .plus.icon {
      color: #ffffff;
    }
  }
}

.changing-skin {
  position: relative;
  padding: 4rem 4rem 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .title {
    margin-bottom: 3rem;
    max-width: 40rem;
    text-align: center;
  }
  > .changing-skin-animation {
    max-width: 400px;
  }
}
