#app .request {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .request__topbar--with-sidebar {
    width: 75%;

    @media screen and (max-width: @bp-md-mx) {
      width: 100%;
    }
  }

  .request__sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 25%;
    padding: 1.5rem 2.5rem 2.5rem;
    background: @c-secondary url('../../images/background-pricelist.svg')
      no-repeat center center;
    background-size: cover;

    @media screen and (max-width: @bp-md-mx) {
      position: relative;
      width: 100%;
      order: 2;
      padding-bottom: 5rem;
    }
  }

  .request__wizard {
    height: 100vh;
    padding-top: 6.25rem;

    @media screen and (max-width: @bp-md-mx) {
      padding-top: 4.5rem;
      width: 100%;
      height: auto;
    }

    &--with-sidebar {
      margin-right: 25%;

      @media screen and (max-width: @bp-md-mx) {
        margin: 0;
      }
    }
  }

  .request__page {
    height: 100%;
  }

  .request__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 1rem;
  }

  button[form='form__Piano'],
  button[form='form__Pre_Pagamento'] {
    margin: 1rem 1rem 1rem auto;
    width: 10rem;
    flex: 1 0 auto;
  }

  // STEPS
  .request__step-wrapper {
    position: fixed;
    top: @height-topbar;
    left: 0;
    width: 75%;
    padding: 1rem 5rem;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid @c-grey-button;
    display: flex;
    align-items: center;
    background: #fff;
    z-index: 9;
    flex-wrap: wrap;

    @media screen and (max-width: 87rem) {
      flex: 0 0 100%;
    }

    @media screen and (max-width: @bp-lg-mx) {
      padding: 1rem 2rem;
    }

    @media screen and (max-width: 57rem) {
      padding: 1rem 6rem;
    }

    @media screen and (max-width: @bp-md-mx) {
      top: 4.75rem;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: start;
      padding: 1rem 2rem;
    }
  }

  .step--parents {
    flex: 1;
    display: flex;
    @media screen and (max-width: 87rem) {
      flex: 0 0 100%;
      justify-content: space-between;
    }
    @media screen and (max-width: 57rem) {
      flex-wrap: wrap;
      .step {
        flex: 0 0 25%;
        padding: 0;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0.6rem;
        }
      }
    }
  }

  .request--navigation {
    display: flex;
    margin-left: auto;
    margin-right: 0;
    flex: 0 0 fit-content;
    align-items: center;
    @media screen and (max-width: 87rem) {
      margin-top: 1rem;
    }
    &.wide {
      width: 100%;
      .step {
        width: 100%;
        padding: 0 5rem;
        button {
          width: 100%;
        }
      }
    }
  }

  .step {
    padding: 0 0.75rem;
    border: 0;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    font-size: @font-14;
    background: transparent;

    @media screen and (max-width: @bp-md-mx) {
      flex: 1 0 0;
      padding: 0 0.25rem;
    }

    &:after {
      content: none;
    }

    &.active {
      background: transparent;

      .step__number {
        border-color: @c-primary;
        background: @c-primary;
        color: #fff;
        font-weight: 600;
      }
    }

    &__name {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 600;
      margin-left: 0.75rem;
      flex: 0 0 fit-content;
      width: fit-content;

      @media screen and (max-width: 57rem) {
        margin-left: 0;
      }

      @media screen and (max-width: @bp-md-mx) {
        margin: 0;
        text-align: center;
        display: none;
      }
    }

    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid @c-grey-light;
      border-radius: 4rem;
    }

    &__last {
      padding: 0;

      i.icon {
        margin: 0;
      }
    }

    &:last-child {
      @media screen and (max-width: @bp-md-mx) {
        width: auto !important;
        margin-left: auto;
      }
    }
  }

  // CONTENT
  .request__container__wrapper {
    height: 100%;
    max-width: 64rem;
    margin: 0 auto;
    padding: 0 1.5rem;

    @media screen and (max-width: @bp-md-mx) {
      width: 100%;
    }
  }

  .request__step-wrapper ~ .request__container__wrapper {
    form.ui.form {
      @media screen and (max-width: 87rem) {
        padding-top: 10.5rem;
      }
      @media screen and (max-width: 57rem) {
        padding-top: 14rem;
      }
      @media screen and (max-width: @bp-md-mx) {
        padding-top: 13rem;
      }
      @media screen and (max-width: 30rem) {
        padding-top: 14rem;
      }
    }
  }

  form.ui.form {
    padding: 7rem 0 3rem;

    h1,
    h2.header.ui,
    h3.header.ui {
      font-size: @font-28;
      font-weight: 400;
      margin: 0;
      text-align: center;
    }

    h3.sub.header,
    h4,
    h5.sub.header {
      font-size: @font-18;
      font-weight: 400;
      margin: 0.75rem 0 1.5rem;
      text-align: center;
    }

    .form__message {
      margin-bottom: 1rem;
      text-align: center;
    }

    .request__company-types,
    .request__document-type {
      button {
        background: transparent;
        border: 1px solid @c-grey-light;
        border-radius: 0.15rem;
        font-size: @font-18 !important;
        font-weight: 500;
        line-height: 1.2;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: @bp-md-mx) {
          margin-bottom: 2rem;
        }
      }
    }

    .request__company-types {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      row-gap: 2rem;

      @media screen and (max-width: @bp-md-mx) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }

      button {
        min-height: 9rem;
        padding: 1.125rem 2rem !important;

        i.icon {
          margin: 0;
          font-size: @font-32;
        }

        &.primary {
          border: 2px solid @c-primary;
          color: #000;
          font-weight: 600;

          i.icon {
            color: @c-primary;
          }
        }
      }
    }

    .request__document-type {
      @media screen and (max-width: @bp-md-mx) {
        width: 100% !important;
      }

      button {
        width: 100%;
        padding: 1.5rem !important;
      }
    }

    .contentGrid {
      @media screen and (max-width: @bp-md-mx) {
        overflow-x: scroll;
        margin-bottom: 2rem;
      }
    }

    table.ui.table {
      margin-bottom: 2rem;

      button {
        // min-height: 9rem;
        padding: 1.125rem 2rem !important;

        i.icon {
          margin: 0;
          font-size: @font-32;
        }

        &.primary {
          border: 2px solid @c-primary;
          color: #000;
          font-weight: 600;

          i.icon {
            color: @c-primary;
          }
        }
      }
    }

    .request__document-type {
      @media screen and (max-width: @bp-md-mx) {
        width: 100% !important;
      }

      button {
        width: 100%;
        padding: 1.5rem !important;
      }
    }

    .contentGrid {
      @media screen and (max-width: @bp-md-mx) {
        overflow-x: scroll;
        margin-bottom: 2rem;
      }
    }

    table.ui.table {
      margin-bottom: 2rem;

      thead th,
      tbody td {
        padding: 0.5rem;
        text-align: center;

        button {
          margin: 0 0.25rem;
        }
      }
    }
  }

  .request__plan_card_selected {
    border: 3px solid @c-primary;
    border-radius: 1rem;
  }

  .request__plan__card:hover {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
  }

  // FOOTER
  .request__sidebar__footer {
    z-index: 99;
  }
}

.undo_request_step {
  cursor: pointer;
}

// Cliente_SceltaCliente variation

button#form__Cliente_SceltaCliente {
  width: 100%;
}
