#app {
  .access {
    height: 100vh;
    background: @c-secondary url('../../images/background-access.svg') no-repeat
      center center;
    background-size: cover;
    overflow-x: hidden;

    &__container {
      height: 100%;
      padding-top: 100px;

      @media screen and (max-width: @bp-md-mx) {
        padding: 80px 0 3rem 0;
        height: auto;
        min-height: 100%;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    h1.header[class*='__title'] {
      font-weight: 500;

      span {
        font-weight: 700;
      }
    }

    &__form {
      padding-top: 0;

      @media screen and (max-width: @bp-md-mx) {
        margin: 0 1.25rem;
        .row {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      .login,
      .register {
        &__password-row {
          padding: 0.5rem 0 2rem;
          @media screen and (max-width: @bp-md-mx) {
            padding: 0.5rem 0;
          }
        }
        &__password-link-register,
        &__password-recovery {
          @media screen and (max-width: @bp-md-mx) {
            width: 100% !important;
            text-align: left;
          }

          a {
            font-size: @font-14;
            font-weight: 600;
            color: @c-primary;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
