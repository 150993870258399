.form.ui.form.form__Investimento {
  .investment__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h1.investment__header__title {
      font-weight: 500;
      font-size: @font-28;

      margin: 1.5rem 0rem;

      span.title__bold {
        font-weight: 700;
      }
    }

    p.investment__header__subtitle {
      font-weight: 500;
      margin: 0 0 1rem 0;

      span.subtitle__bold {
        font-weight: 700;
      }
    }
  }

  .investment__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    div.column {
      > img.ui.image {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
      }
    }

    &__grid {
      width: 80%;
      @media screen and (max-width: 990px) {
        width: 100%;
      }

      div.field {
        > label {
          font-weight: 400;
          font-size: @font-14;
        }
      }

      &__textarea {
        .field {
          width: 100%;

          .ui.input {
            padding-right: 1rem;
            > textarea {
              min-height: 75px;
              height: 75px;
              margin-left: 1rem;
            }
          }
        }

        > span {
          margin-left: 1rem;
        }
      }

      &__dateWarning {
        color: #239f76;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .field.label-on-top {
      position: relative;
      width: 100%;
      .ui.toggle.checkbox {
        position: relative;
        width: 100%;
        label {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          padding-left: 4.6rem;
          margin-top: 0;
          height: fit-content;
          line-height: 21px;
          &:before {
            top: 0;
          }
          &:after {
            top: 0.21rem;
          }
        }
      }
    }

    .row {
      &.investment__content__grid__textarea {
        span {
          position: relative;
          top: 0;
        }
      }
    }
  }
}
