.ui.form#form__Finanziamento {
  .finanziamento__header {
    h1.finanziamento__header__title {
      width: auto;
      strong {
        position: relative;
        > img {
          position: absolute;
          top: 0;
          right: -2rem;
        }
      }
    }
  }
  .column {
    .disabled.field {
      opacity: 0.7;
    }
  }
}
.ui.modal.transition.visible.active {
  h2.finanziamento:first-letter {
    text-transform: uppercase;
  }
}
