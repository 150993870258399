#app {
  .sidebar.sidebar--left {
    visibility: visible;
    display: flex;
    flex-direction: column;
    background: @c-grey-dark;
    min-width: 5rem;
    padding-top: 4.5rem;

    @media screen and (max-width: @bp-md-mx) {
      min-width: auto;
    }

    .sidebar__item {
      text-align: center;
      padding: 0.75rem 1.5rem;

      @media screen and (max-width: @bp-md-mx) {
        padding: 0.75rem 1rem;
      }

      .icon,
      .ui.image {
        margin: 0 auto;
      }
    }
  }
  .ui.inverted.menu {
    .item {
      &.active,
      &:hover {
        color: @c-primary !important;
      }
    }
  }
}
