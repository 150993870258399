#app {
  .register {
    &__button {
      &--submit {
        margin-top: 1em;
      }

      &--to-dashboard {
        margin-top: 1em;
      }
    }

    &__email-icon {
     display: flex;
     justify-content: center;
    }
  }
}