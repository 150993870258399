.tooltip {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  &--right {
    justify-content: flex-start;

    > img {
      margin-inline-start: 0.5em;
    }
  }

  &--left {
    justify-content: flex-start;

    > img {
      margin-inline-end: 0.5em;
    }
  }

  > span {
    > span {
      .ui.header:first-child {
        margin-top: 0;
      }
      display: flex;
      align-items: center;
    }
  }
}
