#app .request .form.ui.form.form__Bilancio {
  .balance__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h1.balance__header__title {
      font-weight: 500;
      font-size: @font-28;

      margin: 1.5rem 0rem;

      span.title__bold {
        font-weight: 700;
      }
    }

    h4.balance__header__subtitle {
      font-weight: 500;
      margin: 0 0 1rem 0;
    }
  }

  .row.checkbox,
  .row.single-input,
  .row.modify--balances {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    > .column {
      width: 80%;
      display: block;
      position: relative;
      @media screen and (max-width: @bp-md-mx) {
        width: 100%;
      }
    }
  }

  .row.modify--balances {
    padding: 1.2rem 0;
    > .column {
      display: flex;
      justify-content: flex-end;
      button {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .row.checkbox {
    padding: 1.5rem 0;
    > .field {
      padding: 0 1rem;
      margin: 0 0 0.7rem 0;
    }
    span {
      padding: 1rem;
      position: relative;
    }
  }

  .row.single-input {
    padding: 0 0 1.5rem 0;
    label {
      position: relative;
      padding-right: 20px;
      > img.ui.image {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  div.balance-required {
    margin: 10px 0 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    > span {
      margin: 0;
      color: @c-error;
    }
  }

  .balance__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    div.ui.grid {
      width: 80%;

      @media screen and (max-width: @bp-md-mx) {
        width: 100%;
      }
      .row {
        > .column {
          padding-right: 0;
          padding-left: 0;
          &:not(:first-child) {
            margin-left: 1rem;
            @media screen and (max-width: 550px) {
              margin: 0;
            }
          }
        }
      }
      [class*='two column'].row > .column {
        width: calc(50% - 0.5rem) !important;
        @media screen and (max-width: 550px) {
          width: 100% !important;
        }
      }
    }

    div.field {
      > label {
        font-weight: 400;
        font-size: @font-14;
      }
    }

    div.column {
      > img.ui.image {
        position: absolute;
        top: 0.5rem;
        right: 0;
      }
    }

    input:read-only {
      opacity: 0.5;
    }

    .balance__information {
      &__container {
        margin: auto;
        padding-bottom: 1rem;
      }

      &__subcontainer {
        min-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        padding: 1.5rem;

        background: #f9f9f9;
        border: 1px solid #c4c4c4;
        border-radius: 8px;
      }

      &__image {
        width: 1.5rem;
        margin-right: 1rem;
      }

      &__text {
        color: #070707;

        &__bold {
          text-decoration: underline;
          color: #8098b2;
          font-weight: 600;
        }
      }
    }
  }
}
