// DEFAULT
button.ui.button {
  border-radius: 10rem;
  padding: 0.9rem 1.5rem !important;
  margin: 0;
  background: @c-grey-button;
  color: @c-grey-dark;
  font-size: @font-14 !important;
  font-weight: 600;

  > div {
    display: flex;

    i.icon:only-child {
      margin: 0 0 0 0.5rem;
    }
  }
}

.ui.buttons .button:first-child {
  border: 1px solid;
}

// PRIMARY
button.ui.button.primary,
button.ui.button--primary,
.button--primary button.ui.button {
  background: @c-primary;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background: @c-primary-dark;
    color: #fff;
  }
}

// NEGATIVE
button.ui.button--primary--negative,
.button--primary--negative button.ui.button {
  background: transparent;
  color: @c-primary;
  border-color: @c-primary;
  border: 1px solid @c-primary;

  &:hover,
  &:focus,
  &:active {
    background: @c-primary;
    color: #fff;
  }
}

// UNDERLINE
button.ui.button--underline,
a.ui.button--underline {
  text-decoration: underline;
  background: transparent;
  padding: 0.9rem 0.5rem !important;
  > i.icon {
    text-decoration: none;
    margin-right: 10px !important;
  }

  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
}

// UNDERLINE NEGATIVE
button.ui.button--underline--negative,
a.ui.button--underline--negative {
  text-decoration: underline;
  background: transparent;
  padding: 0.9rem 0.5rem !important;
  color: @c-primary;
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }
}

// SUCCESS
button.ui.button--success {
  background: @c-success;
  color: #fff;
}

// DARK
button.ui.button--dark,
.button--dark button.ui.button {
  background: #000;
  color: #fff;
}
