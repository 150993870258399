div.homepage-container {
  div.ui.center.aligned.segment {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;

    .homeHeader {
      max-width: 50rem;
      margin: 0 auto;
      padding: 10vh 0;

      h1 {
        font-size: 2.5rem;
        margin-bottom: .75rem;
      }

      p {
        margin: 0;
        font-size: @font-18;
        font-weight: 500;
      }
    }

    .homeTiles {
      padding: 0 2rem;
      max-width: 80rem;
      margin: 0 auto;
      margin-bottom: 4rem;
      display: flex;
      justify-content: space-between;

      .tile {
        margin: 0 !important;
        width: 32%;
        padding: 2.5rem;
        border: 0;
        border-radius: .5rem;
        box-shadow: none;

        .tileIcon {
          height: 1.75rem;
          width: 1.75rem;
          margin: 0 auto;

          &[data-icon='calendar'] {
            background: url('/src/assets/images/icon/calendar.svg') no-repeat center center;
            background-size: contain;
          }

          &[data-icon='orders'] {
            background: url('/src/assets/images/icon/checklist.svg') no-repeat center center;
            background-size: contain;
          }

          &[data-icon='document'] {
            background: url('/src/assets/images/icon/document.svg') no-repeat center center;
            background-size: contain;
          }
        }

        .tileTitle {
          font-size: @font-24;
          margin: 1rem 0;
        }

        .tileDescription {
          font-size: @font-14;
          font-weight: 500;
          margin-bottom: 2rem;
        }
      }
    }

    .homeMessage {
      font-size: @font-14;
      font-weight: 500;
      padding-top: 4rem;

      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}
