.ui.dividing.header[class$='Header'] {
  margin: 0;
  padding: 2rem;
  border-color: @c-grey-light;

  @media screen and (max-width: @bp-md-mx) {
    padding: 1rem;
  }

  .ui.grid {
    margin: 0;
    align-items: center;

    .column {
      padding: 0;
      &:last-child {
        flex: 1;
      }
      &.three.wide {
        width: 18.5% !important;
        @media screen and (max-width: 1000px) {
          width: 25% !important;
        }
        @media screen and (max-width: 800px) {
          width: 100% !important;
        }
      }
      .ui.form {
        flex: 1;
      }

      @media screen and (max-width: 800px) {
        width: 100% !important;
        padding-bottom: 1rem;
      }
    }
  }

  h1 {
    font-size: @font-32;
    font-weight: 500;
    margin-right: 2rem;

    @media screen and (max-width: @bp-md-mx) {
      font-size: @font-28;
    }
  }

  .padded.grid {
    width: 100%;
    .row {
      padding: 0;
      align-items: center;

      .column {
        min-width: 15rem;
        &.voucher__buttons {
          min-width: unset;
          width: fit-content !important;
          margin-right: 30px;
        }
        &.generate-voucher,
        &.add-agent {
          margin-left: auto;
          margin-right: 0;
          justify-content: flex-end;
        }
        &.add-agent {
          display: flex;
          @media screen and (max-width: 800px) {
            justify-content: flex-start;
            padding-bottom: 0;
          }
        }
        &.agentSearch {
          min-width: 20rem;
        }
      }

      .field {
        margin: 0 1.5rem 0 0;
        display: flex;
      }
    }

    @media screen and (max-width: 1400px) {
      .row .column.generate-voucher {
        margin-top: 10px;
        margin-right: auto;
        margin-left: 0;
      }
    }

    [class*='buttons'] {
      display: flex;
      position: relative;

      .button--underline {
        max-width: fit-content;
      }

      @media screen and (max-width: 1580px) {
        width: 100% !important;
        margin-top: 10px;
        &.customerReq {
          margin-top: 0;
          .ui.buttons {
            margin-top: 0;
          }
        }
        .column.generate-voucher {
          margin-top: 10px;
        }
      }

      > .button--primary {
        margin-right: 1.5rem;
      }
    }
  }
}
