#form__Cliente_Riepilogo {
  label.tooltip {
    display: flex;
    img {
      margin-left: 10px;
    }
  }
  .ui.grid {
    .column.row {
      .column[class*='wide'] {
        @media screen and (max-width: 720px) {
          width: 100% !important;
        }
      }
    }
    .column {
      &.fraction-flag {
        height: 100%;
        .field {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
      &.flooding-flag {
        height: 100%;
        .field {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
      .ui.checkbox {
        position: relative;
        input.hidden {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
