#app {
  .investment__detail__grey {
    &__label {
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 1rem;
      color: #070707;
      background-color: #f9f9f9;
    }

    &__input {
      .field {
        > input {
          border: 1px solid #c4c4c4;
          border-radius: 5px;
          padding: 1.15rem;

          &::placeholder {
            opacity: 0.5;
          }
        }

        .ui.input {
          > input {
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            padding: 1.15rem;

            &::placeholder {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .investment__detail__blue {
    &__label {
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 1rem;
      color: #ffffff;
      background-color: #8098b2;
    }

    &__input {
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 1rem;
      color: #ffffff;
      background-color: #8098b2;
    }
  }

  .investment__detail__cream {
    &__label {
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 1rem;
      color: #070707;
      background-color: #f1ece3;
    }

    &__input {
      .field {
        .ui.input {
          > input {
            border: 1px solid #c4c4c4;
            background-color: #f1ece3;
            border-radius: 5px;
            padding: 1.15rem;
          }
        }
      }
    }
  }
}
