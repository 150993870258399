/* HEADER
  ----------------------------- */
#app .sidebar.sidebar--left + .topbar {
  padding-left: 7rem;

  @media screen and (max-width: @bp-md-mx) {
    padding-left: 4rem;
    padding-right: 1rem;
  }
}

#app .topbar {
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1.75rem 5rem;
  border-bottom: 1px solid @c-grey-light;
  border-radius: 0;
  background: #fff;
  z-index: 9;
  height: 100px;

  @media screen and (max-width: @bp-md-mx) {
    padding: 1rem 2rem;
    height: 80px;
  }

  .item {
    padding: 0;

    button.ui.button {
      margin: 0;

      @media screen and (max-width: @bp-md-mx) {
        height: 2.75rem;
        width: 2.75rem;
        font-size: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .right.menu > .item {
    margin-left: 1.5rem;

    @media screen and (max-width: @bp-md-mx) {
      margin-left: 0.75rem;
    }
  }

  .link__terms button.ui.button {
    @media screen and (max-width: @bp-md-mx) {
      background: #ebebeb url('../../images/icon/document-dark.svg') no-repeat
        center center;
      background-size: 1.25rem;
    }
  }

  .link__request button.ui.button {
    @media screen and (max-width: @bp-md-mx) {
      background: #ebebeb url('../../images/icon/invoice-dark.svg') no-repeat
        center center;
      background-size: 1.25rem;
    }
  }

  .link__home button.ui.button {
    @media screen and (max-width: @bp-md-mx) {
      background: #ebebeb url('../../images/icon/home.svg') no-repeat center
        center;
      background-size: 1.25rem;
    }
  }

  .link__logout button.ui.button {
    @media screen and (max-width: @bp-md-mx) {
      background: #ebebeb url('../../images/icon/logout.svg') no-repeat center
        center;
      background-size: 1.25rem;
    }
  }
}
