#app {
  .voucher-container {
    .padded.grid {
      .row {
        padding: 0;
        align-items: center;
        .column:nth-child(3) {
          .field {
            font-weight: normal;
          }
        }

        .field {
          margin: 0 1.5rem 0 0;
          display: flex;
          > * {
            width: 100%;
          }
        }
      }

      .customer__buttons {
        display: flex;

        > .button--primary {
          margin-right: 1.5rem;
        }
      }
    }
  }

  .voucherSectionBody {
    max-width: 90rem;
    margin: 0 auto;
    padding: 2.5rem 2.5rem 4rem;

    @media screen and (max-width: @bp-md-mx) {
      padding: 2.5rem 1rem 4rem;
    }

    .contentGrid {
      @media screen and (max-width: @bp-md-mx) {
        overflow-x: scroll;
        padding-bottom: 2rem;
      }
    }
  }
}

#form__generateVoucher {
  .field.discountPercentage {
    .ui.selection.dropdown {
      > .text {
        opacity: 0;
      }
    }
    &.filled {
      .ui.selection.dropdown {
        > .text {
          opacity: 1;
        }
      }
    }
    &.error {
      .ui.selection.dropdown {
        > .text {
          opacity: 0;
        }
      }
    }
  }
}
