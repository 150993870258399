/* FONT TYPE
  ----------------------------- */
/* TOPBAR SIZE
    ----------------------------- */
/* CHECKBOX SIZE
    ----------------------------- */
/* FONT SIZE
    ----------------------------- */
/* COLORS
  ----------------------------- */
/* BREAKPOINTS
  ----------------------------- */
@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
div#app {
  height: 100%;
  font-family: 'Avenir Next', 'Helvetica', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
}
* {
  box-sizing: border-box;
}
.fluid {
  width: 100%;
  height: auto;
}
.fluid-y {
  height: 100%;
}
.cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.pointer {
  cursor: pointer;
}
/* HEADER
  ----------------------------- */
#app .sidebar.sidebar--left + .topbar {
  padding-left: 7rem;
}
@media screen and (max-width: 48rem) {
  #app .sidebar.sidebar--left + .topbar {
    padding-left: 4rem;
    padding-right: 1rem;
  }
}
#app .topbar {
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1.75rem 5rem;
  border-bottom: 1px solid #C4C4C4;
  border-radius: 0;
  background: #fff;
  z-index: 9;
  height: 100px;
}
@media screen and (max-width: 48rem) {
  #app .topbar {
    padding: 1rem 2rem;
    height: 80px;
  }
}
#app .topbar .item {
  padding: 0;
}
#app .topbar .item button.ui.button {
  margin: 0;
}
@media screen and (max-width: 48rem) {
  #app .topbar .item button.ui.button {
    height: 2.75rem;
    width: 2.75rem;
    font-size: 0 !important;
    padding: 0 !important;
  }
}
#app .topbar .right.menu > .item {
  margin-left: 1.5rem;
}
@media screen and (max-width: 48rem) {
  #app .topbar .right.menu > .item {
    margin-left: 0.75rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .link__terms button.ui.button {
    background: #ebebeb url('../../assets/images/icon/document-dark.svg') no-repeat center center;
    background-size: 1.25rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .link__request button.ui.button {
    background: #ebebeb url('../../assets/images/icon/invoice-dark.svg') no-repeat center center;
    background-size: 1.25rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .link__home button.ui.button {
    background: #ebebeb url('../../assets/images/icon/home.svg') no-repeat center center;
    background-size: 1.25rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .topbar .link__logout button.ui.button {
    background: #ebebeb url('../../assets/images/icon/logout.svg') no-repeat center center;
    background-size: 1.25rem;
  }
}
div.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.5rem 0;
  text-align: center;
  background: #3B4148;
}
div.footer div.footer-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #FFF;
}
button.ui.button {
  border-radius: 10rem;
  padding: 0.9rem 1.5rem !important;
  margin: 0;
  background: #EBEBEB;
  color: #070707;
  font-size: 0.875rem !important;
  font-weight: 600;
}
button.ui.button > div {
  display: flex;
}
button.ui.button > div i.icon:only-child {
  margin: 0 0 0 0.5rem;
}
.ui.buttons .button:first-child {
  border: 1px solid;
}
button.ui.button.primary,
button.ui.button--primary,
.button--primary button.ui.button {
  background: #8098B2;
  color: #fff;
}
button.ui.button.primary:hover,
button.ui.button--primary:hover,
.button--primary button.ui.button:hover,
button.ui.button.primary:focus,
button.ui.button--primary:focus,
.button--primary button.ui.button:focus,
button.ui.button.primary:active,
button.ui.button--primary:active,
.button--primary button.ui.button:active {
  background: #396288;
  color: #fff;
}
button.ui.button--primary--negative,
.button--primary--negative button.ui.button {
  background: transparent;
  color: #8098B2;
  border-color: #8098B2;
  border: 1px solid #8098B2;
}
button.ui.button--primary--negative:hover,
.button--primary--negative button.ui.button:hover,
button.ui.button--primary--negative:focus,
.button--primary--negative button.ui.button:focus,
button.ui.button--primary--negative:active,
.button--primary--negative button.ui.button:active {
  background: #8098B2;
  color: #fff;
}
button.ui.button--underline,
a.ui.button--underline {
  text-decoration: underline;
  background: transparent;
  padding: 0.9rem 0.5rem !important;
}
button.ui.button--underline > i.icon,
a.ui.button--underline > i.icon {
  text-decoration: none;
  margin-right: 10px !important;
}
button.ui.button--underline:hover,
a.ui.button--underline:hover,
button.ui.button--underline:focus,
a.ui.button--underline:focus,
button.ui.button--underline:active,
a.ui.button--underline:active {
  background: transparent;
}
button.ui.button--underline--negative,
a.ui.button--underline--negative {
  text-decoration: underline;
  background: transparent;
  padding: 0.9rem 0.5rem !important;
  color: #8098B2;
  font-weight: 500;
}
button.ui.button--underline--negative:hover,
a.ui.button--underline--negative:hover,
button.ui.button--underline--negative:focus,
a.ui.button--underline--negative:focus,
button.ui.button--underline--negative:active,
a.ui.button--underline--negative:active {
  background: transparent;
}
button.ui.button--success {
  background: #239F76;
  color: #fff;
}
button.ui.button--dark,
.button--dark button.ui.button {
  background: #000;
  color: #fff;
}
@media screen and (max-width: 48rem) {
  form.ui.form .grid > .row > .column {
    width: 100% !important;
  }
  form.ui.form .grid > .row > .column:first-child {
    padding-bottom: 1rem;
  }
}
form.ui.form .field {
  position: relative;
  margin: 0 0 0.35rem;
}
form.ui.form .field label {
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.5rem;
}
form.ui.form .field label a {
  color: #8098B2;
  font-weight: 500;
  text-decoration: underline;
}
form.ui.form .field input,
form.ui.form .field .selection.dropdown {
  position: relative;
  border-color: #C4C4C4;
  border-radius: 0.125rem;
  padding: 0.65rem 2rem 0.65rem 1rem;
  font-weight: 500;
  width: 100%;
  flex: 0 0 100%;
}
form.ui.form .field input > .dropdown.icon,
form.ui.form .field .selection.dropdown > .dropdown.icon {
  top: 50%;
  transform: translateY(-50%);
  margin: 0 -0.6875em 0 0;
}
form.ui.form .field input::placeholder,
form.ui.form .field .selection.dropdown::placeholder {
  color: #9B9B9B;
}
form.ui.form .field .ui.checkbox:not(.toggle) input {
  height: 1.5rem;
  width: 1.5rem;
}
form.ui.form .field .ui.checkbox:not(.toggle) label {
  text-align: left;
  margin: 0;
  padding-left: 2.5rem;
}
form.ui.form .field .ui.checkbox:not(.toggle) label:before {
  height: 1.5rem;
  width: 1.5rem;
  border-color: #9B9B9B;
}
form.ui.form .field .ui.checkbox:not(.toggle).checked label:before {
  background: #239F76;
  border-color: #239F76;
}
form.ui.form .field .ui.checkbox:not(.toggle).checked label:after {
  content: '';
  height: 1.5rem;
  width: 1.5rem;
  background: url('../../assets/images/icon/done.svg') no-repeat center center;
  background-size: 80%;
}
form.ui.form .field input.hidden {
  position: absolute;
  top: 0;
  left: 0;
}
form.ui.form .field .ui.checkbox.toggle label:before {
  width: 4rem;
  height: 2rem;
  top: -0.25rem;
}
form.ui.form .field .ui.checkbox.toggle label:after {
  width: 1.625rem;
  height: 1.625rem;
  box-shadow: none;
  background: #fff;
  top: -0.05rem;
  left: 0.2rem;
}
form.ui.form .field .ui.checkbox.toggle input:checked ~ label:before {
  background-color: #239F76 !important;
}
form.ui.form .field .ui.checkbox.toggle input:checked ~ label:after {
  left: 2.2rem;
}
form.ui.form .field + span {
  font-size: 0.75rem;
}
form.ui.form .field.error + span {
  color: #EB5757;
  position: absolute;
  top: 100%;
  line-height: 1em;
}
span.error {
  color: #eb5757;
  font-size: 0.75rem;
  position: absolute;
  top: 100%;
  line-height: 1em;
}
span.error.error-all {
  font-size: 0.875rem;
  line-height: 19px;
  display: block;
  position: relative;
  top: 0;
}
.ui.form .field.error .ui.dropdown .text {
  background: transparent;
}
.ui.form .field.error .ui.dropdown .selected.item {
  font-weight: bold;
}
/* Datepicker */
.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  border-radius: 0.125rem !important;
  border: 1px solid #c4c4c4 !important;
  display: block;
}
.MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
  width: 100%;
}
.MuiFormControl-root.MuiTextField-root .MuiInputBase-adornedEnd.MuiInputBase-formControl input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
  width: 100%;
  position: relative;
  max-width: 100%;
  flex: unset;
}
.MuiFormControl-root.MuiTextField-root div.ui.label.dropdown[role='listbox'],
.MuiFormControl-root.MuiTextField-root div:not(.select__input) > input[type='number'],
.MuiFormControl-root.MuiTextField-root div:not(.select__input) > input[type='text'],
.MuiFormControl-root.MuiTextField-root .select__control {
  border: 0 !important;
}
.ui.selection.dropdown {
  min-width: unset;
}
span.error {
  color: #EB5757;
  position: absolute;
  top: 100%;
  line-height: 1em;
  font-size: 0.75rem;
}
.ui.form .field.error .input {
  color: #EB5757;
}
.ui.page.modals {
  background-color: rgba(0, 0, 0, 0.25);
  /** Modal Request */
}
.ui.page.modals > div {
  position: relative;
}
.ui.page.modals .modal {
  box-shadow: none;
}
.ui.page.modals .modal .close.icon {
  margin: 0;
  padding: 0;
  font-size: 0;
  background: url('/src/assets/images/icon/close.svg') no-repeat center center;
  background-size: 50%;
  top: 0.5rem;
  right: 0.5rem;
}
.ui.page.modals .modal > div.header {
  color: #8098B2;
  font-weight: 500;
  font-size: 1.5rem;
  background-color: transparent;
  border-bottom: none;
}
.ui.page.modals .modal > div.header .tooltip--left span {
  padding-right: 1.5rem;
  font-weight: 700;
  color: #070707;
}
.ui.page.modals .modal .content {
  padding: 2.5rem;
}
@media screen and (max-width: 769px) {
  .ui.page.modals .modal .content {
    padding: 1rem !important;
  }
}
.ui.page.modals .modal .content h3.header {
  font-size: 1.5rem;
  font-weight: 500;
}
.ui.page.modals .modal .content a {
  color: #8098B2;
}
.ui.page.modals .modal > div.noPaddingTop.content {
  padding-top: 0;
}
.ui.page.modals .modal .actions {
  padding: 1rem !important;
}
.ui.page.modals .modal .actions button {
  margin: 0 0 0 1rem;
}
@media screen and (max-width: 769px) {
  .ui.page.modals {
    padding: 1rem !important;
  }
  .ui.page.modals > div {
    width: 100%;
  }
}
.ui.page.modals .modal__requestHeader {
  padding: 1.25rem 2.5rem;
}
@media screen and (max-width: 48rem) {
  .ui.page.modals .modal__requestHeader {
    padding: 1.5rem 1rem !important;
  }
}
.ui.page.modals .modal__requestHeader .steps .step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.125rem;
  line-height: 1.2;
  padding: 1rem 2rem;
}
@media screen and (max-width: 48rem) {
  .ui.page.modals .modal__requestHeader .steps .step {
    flex-direction: row;
    align-items: center;
  }
}
.ui.page.modals .modal__requestHeader .steps .step .icon {
  font-size: 1.5rem;
}
@media screen and (max-width: 48rem) {
  .ui.page.modals .modal__requestHeader .steps .step .icon {
    margin: 0 0.5rem 0 0;
  }
}
.ui.page.modals .modal__requestHeader .steps .step span {
  margin-top: 0.75rem;
}
@media screen and (max-width: 48rem) {
  .ui.page.modals .modal__requestHeader .steps .step span {
    margin: 0;
  }
}
.ui.page.modals .modal__requestHeader .steps .step.active {
  background: #F9F9F9;
  font-weight: 600;
}
.ui.page.modals .modal__requestHeader .steps .step.active .icon {
  color: #8098B2;
}
.ui.page.modals .modal__requestHeader .steps .step.completed .icon:before {
  color: #239F76;
}
.ui.page.modals [class*='appointment_modal_actions'] {
  padding: 1.25rem 2.5rem;
}
.ui.page.modals [class*='appointment_modal_actions'] button:last-child {
  background: #239F76;
  color: #fff;
}
.ui.page.modals .ui.error.input input[type='text'] {
  color: #9f3a38;
  background: #fff6f6;
}
.ui.page.modals label.error {
  color: #9f3a38;
}
.ui.page.modals span.error {
  color: #eb5757;
}
/** Legal representative modal */
@media screen and (max-width: 769px) {
  .ui.page.modals .actions .field {
    display: flex;
  }
  .ui.page.modals .actions .field button[form='legal-representative'],
  .ui.page.modals .actions .field button[form='group-structure'] {
    margin-left: 0;
    margin-right: auto;
  }
}
#app .sidebar.sidebar--left {
  visibility: visible;
  display: flex;
  flex-direction: column;
  background: #070707;
  min-width: 5rem;
  padding-top: 4.5rem;
}
@media screen and (max-width: 48rem) {
  #app .sidebar.sidebar--left {
    min-width: auto;
  }
}
#app .sidebar.sidebar--left .sidebar__item {
  text-align: center;
  padding: 0.75rem 1.5rem;
}
@media screen and (max-width: 48rem) {
  #app .sidebar.sidebar--left .sidebar__item {
    padding: 0.75rem 1rem;
  }
}
#app .sidebar.sidebar--left .sidebar__item .icon,
#app .sidebar.sidebar--left .sidebar__item .ui.image {
  margin: 0 auto;
}
#app .ui.inverted.menu .item.active,
#app .ui.inverted.menu .item:hover {
  color: #8098B2 !important;
}
table.ui.table {
  margin: 0;
  background: transparent;
  border: 0;
  border-collapse: collapse;
}
table.ui.table thead th {
  background: #3B4148;
  color: #fff;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 48rem) {
  table.ui.table thead th {
    padding: 0.5rem 1rem;
  }
}
table.ui.table thead th div label {
  background: #3B4148;
  color: #fff !important;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}
table.ui.table tbody {
  background: #fff;
}
table.ui.table tbody td {
  padding: 1.5rem 2.5rem;
}
@media screen and (max-width: 48rem) {
  table.ui.table tbody td {
    padding: 1rem 1.5rem;
  }
}
table.ui.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}
table.ui.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}
table.ui.table tfoot th {
  background: transparent;
  padding: 2rem 0 0;
  border: 0;
}
table.ui.table tfoot .ui.pagination {
  background: transparent;
  border: none;
  box-shadow: none;
}
table.ui.table tfoot .ui.pagination .item {
  margin-left: 0.5rem;
  border-radius: 10rem;
  display: inline-block;
  background: #fff;
  border: 1px solid #C4C4C4;
}
table.ui.table tfoot .ui.pagination .item:before {
  content: none;
}
table.ui.table tfoot .ui.pagination .item:hover {
  background: #3B4148;
  color: #fff;
}
.tooltip {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.tooltip--right {
  justify-content: flex-start;
}
.tooltip--right > img {
  margin-inline-start: 0.5em;
}
.tooltip--left {
  justify-content: flex-start;
}
.tooltip--left > img {
  margin-inline-end: 0.5em;
}
.tooltip > span > span {
  display: flex;
  align-items: center;
}
.tooltip > span > span .ui.header:first-child {
  margin-top: 0;
}
#app .tabular.menu {
  border: 0;
}
#app .tabular.menu .item {
  border: 0;
  border-radius: 0.5rem 0.5rem 0 0 !important;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 0.875rem;
  background: #FFF;
}
#app .tabular.menu .item:not(.active) {
  opacity: 0.65;
}
#app .active.tab {
  border: 0;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: 1.5rem;
}
@media screen and (max-width: 48rem) {
  #app .active.tab {
    padding: 1rem;
  }
}
#app .active.tab h3 {
  font-size: 1.375rem;
  font-weight: 500;
}
#app .currency_input_formik::placeholder {
  opacity: 0.5;
}
#app .investment__detail__grey__label {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem;
  color: #070707;
  background-color: #f9f9f9;
}
#app .investment__detail__grey__input .field > input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1.15rem;
}
#app .investment__detail__grey__input .field > input::placeholder {
  opacity: 0.5;
}
#app .investment__detail__grey__input .field .ui.input > input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1.15rem;
}
#app .investment__detail__grey__input .field .ui.input > input::placeholder {
  opacity: 0.5;
}
#app .investment__detail__blue__label {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem;
  color: #ffffff;
  background-color: #8098b2;
}
#app .investment__detail__blue__input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem;
  color: #ffffff;
  background-color: #8098b2;
}
#app .investment__detail__cream__label {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem;
  color: #070707;
  background-color: #f1ece3;
}
#app .investment__detail__cream__input .field .ui.input > input {
  border: 1px solid #c4c4c4;
  background-color: #f1ece3;
  border-radius: 5px;
  padding: 1.15rem;
}
.fc.fc-theme-standard .fc-toolbar-title {
  font-size: 1.75rem;
  font-weight: 500;
}
@media screen and (max-width: 48rem) {
  .fc.fc-theme-standard .fc-toolbar-title {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 48rem) {
  .fc.fc-theme-standard .fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }
}
.fc.fc-theme-standard .fc-col-header-cell-cushion {
  color: #000;
  font-weight: 500;
}
.fc.fc-theme-standard .fc-bg-event {
  background-color: #239F76 !important;
  opacity: 0.75;
}
.fc.fc-theme-standard .fc-v-event {
  background: #396288;
  border-color: #396288;
  border-radius: 0;
  box-shadow: 0 0 0 1px #EBEBEB;
}
.ui[class*='center aligned'].grid {
  margin: 0;
}
#app .access {
  height: 100vh;
  background: #F1ECE3 url('../../assets/images/background-access.svg') no-repeat center center;
  background-size: cover;
  overflow-x: hidden;
}
#app .access__container {
  height: 100%;
  padding-top: 100px;
}
@media screen and (max-width: 48rem) {
  #app .access__container {
    padding: 80px 0 3rem 0;
    height: auto;
    min-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}
#app .access h1.header[class*='__title'] {
  font-weight: 500;
}
#app .access h1.header[class*='__title'] span {
  font-weight: 700;
}
#app .access__form {
  padding-top: 0;
}
@media screen and (max-width: 48rem) {
  #app .access__form {
    margin: 0 1.25rem;
  }
  #app .access__form .row {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
#app .access__form .login__password-row,
#app .access__form .register__password-row {
  padding: 0.5rem 0 2rem;
}
@media screen and (max-width: 48rem) {
  #app .access__form .login__password-row,
  #app .access__form .register__password-row {
    padding: 0.5rem 0;
  }
}
@media screen and (max-width: 48rem) {
  #app .access__form .login__password-link-register,
  #app .access__form .register__password-link-register,
  #app .access__form .login__password-recovery,
  #app .access__form .register__password-recovery {
    width: 100% !important;
    text-align: left;
  }
}
#app .access__form .login__password-link-register a,
#app .access__form .register__password-link-register a,
#app .access__form .login__password-recovery a,
#app .access__form .register__password-recovery a {
  font-size: 0.875rem;
  font-weight: 600;
  color: #8098B2;
  text-decoration: underline;
}
#app .standardlayout__container {
  min-height: 100vh;
  padding: 6.25rem 0 0 5rem;
  background: #F1ECE3 url('../../assets/images/background-access.svg') no-repeat center center;
  background-size: cover;
}
@media screen and (max-width: 48rem) {
  #app .standardlayout__container {
    padding: 4.75rem 0 4.75rem 3.25rem;
  }
}
#app .request {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}
#app .request .request__topbar--with-sidebar {
  width: 75%;
}
@media screen and (max-width: 48rem) {
  #app .request .request__topbar--with-sidebar {
    width: 100%;
  }
}
#app .request .request__sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 25%;
  padding: 1.5rem 2.5rem 2.5rem;
  background: #F1ECE3 url('../../assets/images/background-pricelist.svg') no-repeat center center;
  background-size: cover;
}
@media screen and (max-width: 48rem) {
  #app .request .request__sidebar {
    position: relative;
    width: 100%;
    order: 2;
    padding-bottom: 5rem;
  }
}
#app .request .request__wizard {
  height: 100vh;
  padding-top: 6.25rem;
}
@media screen and (max-width: 48rem) {
  #app .request .request__wizard {
    padding-top: 4.5rem;
    width: 100%;
    height: auto;
  }
}
#app .request .request__wizard--with-sidebar {
  margin-right: 25%;
}
@media screen and (max-width: 48rem) {
  #app .request .request__wizard--with-sidebar {
    margin: 0;
  }
}
#app .request .request__page {
  height: 100%;
}
#app .request .request__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1rem;
}
#app .request button[form='form__Piano'],
#app .request button[form='form__Pre_Pagamento'] {
  margin: 1rem 1rem 1rem auto;
  width: 10rem;
  flex: 1 0 auto;
}
#app .request .request__step-wrapper {
  position: fixed;
  top: 6.25rem;
  left: 0;
  width: 75%;
  padding: 1rem 5rem;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 9;
  flex-wrap: wrap;
}
@media screen and (max-width: 87rem) {
  #app .request .request__step-wrapper {
    flex: 0 0 100%;
  }
}
@media screen and (max-width: 64rem) {
  #app .request .request__step-wrapper {
    padding: 1rem 2rem;
  }
}
@media screen and (max-width: 57rem) {
  #app .request .request__step-wrapper {
    padding: 1rem 6rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .request .request__step-wrapper {
    top: 4.75rem;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    padding: 1rem 2rem;
  }
}
#app .request .step--parents {
  flex: 1;
  display: flex;
}
@media screen and (max-width: 87rem) {
  #app .request .step--parents {
    flex: 0 0 100%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 57rem) {
  #app .request .step--parents {
    flex-wrap: wrap;
  }
  #app .request .step--parents .step {
    flex: 0 0 25%;
    padding: 0;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
  #app .request .step--parents .step:nth-child(1),
  #app .request .step--parents .step:nth-child(2),
  #app .request .step--parents .step:nth-child(3),
  #app .request .step--parents .step:nth-child(4) {
    margin-bottom: 0.6rem;
  }
}
#app .request .request--navigation {
  display: flex;
  margin-left: auto;
  margin-right: 0;
  flex: 0 0 fit-content;
  align-items: center;
}
@media screen and (max-width: 87rem) {
  #app .request .request--navigation {
    margin-top: 1rem;
  }
}
#app .request .request--navigation.wide {
  width: 100%;
}
#app .request .request--navigation.wide .step {
  width: 100%;
  padding: 0 5rem;
}
#app .request .request--navigation.wide .step button {
  width: 100%;
}
#app .request .step {
  padding: 0 0.75rem;
  border: 0;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  font-size: 0.875rem;
  background: transparent;
}
@media screen and (max-width: 48rem) {
  #app .request .step {
    flex: 1 0 0;
    padding: 0 0.25rem;
  }
}
#app .request .step:after {
  content: none;
}
#app .request .step.active {
  background: transparent;
}
#app .request .step.active .step__number {
  border-color: #8098B2;
  background: #8098B2;
  color: #fff;
  font-weight: 600;
}
#app .request .step__name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-left: 0.75rem;
  flex: 0 0 fit-content;
  width: fit-content;
}
@media screen and (max-width: 57rem) {
  #app .request .step__name {
    margin-left: 0;
  }
}
@media screen and (max-width: 48rem) {
  #app .request .step__name {
    margin: 0;
    text-align: center;
    display: none;
  }
}
#app .request .step__number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #C4C4C4;
  border-radius: 4rem;
}
#app .request .step__last {
  padding: 0;
}
#app .request .step__last i.icon {
  margin: 0;
}
@media screen and (max-width: 48rem) {
  #app .request .step:last-child {
    width: auto !important;
    margin-left: auto;
  }
}
#app .request .request__container__wrapper {
  height: 100%;
  max-width: 64rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
@media screen and (max-width: 48rem) {
  #app .request .request__container__wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 87rem) {
  #app .request .request__step-wrapper ~ .request__container__wrapper form.ui.form {
    padding-top: 10.5rem;
  }
}
@media screen and (max-width: 57rem) {
  #app .request .request__step-wrapper ~ .request__container__wrapper form.ui.form {
    padding-top: 14rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .request .request__step-wrapper ~ .request__container__wrapper form.ui.form {
    padding-top: 13rem;
  }
}
@media screen and (max-width: 30rem) {
  #app .request .request__step-wrapper ~ .request__container__wrapper form.ui.form {
    padding-top: 14rem;
  }
}
#app .request form.ui.form {
  padding: 7rem 0 3rem;
}
#app .request form.ui.form h1,
#app .request form.ui.form h2.header.ui,
#app .request form.ui.form h3.header.ui {
  font-size: 1.75rem;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
#app .request form.ui.form h3.sub.header,
#app .request form.ui.form h4,
#app .request form.ui.form h5.sub.header {
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0.75rem 0 1.5rem;
  text-align: center;
}
#app .request form.ui.form .form__message {
  margin-bottom: 1rem;
  text-align: center;
}
#app .request form.ui.form .request__company-types button,
#app .request form.ui.form .request__document-type button {
  background: transparent;
  border: 1px solid #C4C4C4;
  border-radius: 0.15rem;
  font-size: 1.125rem !important;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 48rem) {
  #app .request form.ui.form .request__company-types button,
  #app .request form.ui.form .request__document-type button {
    margin-bottom: 2rem;
  }
}
#app .request form.ui.form .request__company-types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}
@media screen and (max-width: 48rem) {
  #app .request form.ui.form .request__company-types {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
#app .request form.ui.form .request__company-types button {
  min-height: 9rem;
  padding: 1.125rem 2rem !important;
}
#app .request form.ui.form .request__company-types button i.icon {
  margin: 0;
  font-size: 2rem;
}
#app .request form.ui.form .request__company-types button.primary {
  border: 2px solid #8098B2;
  color: #000;
  font-weight: 600;
}
#app .request form.ui.form .request__company-types button.primary i.icon {
  color: #8098B2;
}
@media screen and (max-width: 48rem) {
  #app .request form.ui.form .request__document-type {
    width: 100% !important;
  }
}
#app .request form.ui.form .request__document-type button {
  width: 100%;
  padding: 1.5rem !important;
}
@media screen and (max-width: 48rem) {
  #app .request form.ui.form .contentGrid {
    overflow-x: scroll;
    margin-bottom: 2rem;
  }
}
#app .request form.ui.form table.ui.table {
  margin-bottom: 2rem;
}
#app .request form.ui.form table.ui.table button {
  padding: 1.125rem 2rem !important;
}
#app .request form.ui.form table.ui.table button i.icon {
  margin: 0;
  font-size: 2rem;
}
#app .request form.ui.form table.ui.table button.primary {
  border: 2px solid #8098B2;
  color: #000;
  font-weight: 600;
}
#app .request form.ui.form table.ui.table button.primary i.icon {
  color: #8098B2;
}
@media screen and (max-width: 48rem) {
  #app .request form.ui.form .request__document-type {
    width: 100% !important;
  }
}
#app .request form.ui.form .request__document-type button {
  width: 100%;
  padding: 1.5rem !important;
}
@media screen and (max-width: 48rem) {
  #app .request form.ui.form .contentGrid {
    overflow-x: scroll;
    margin-bottom: 2rem;
  }
}
#app .request form.ui.form table.ui.table {
  margin-bottom: 2rem;
}
#app .request form.ui.form table.ui.table thead th,
#app .request form.ui.form table.ui.table tbody td {
  padding: 0.5rem;
  text-align: center;
}
#app .request form.ui.form table.ui.table thead th button,
#app .request form.ui.form table.ui.table tbody td button {
  margin: 0 0.25rem;
}
#app .request .request__plan_card_selected {
  border: 3px solid #8098B2;
  border-radius: 1rem;
}
#app .request .request__plan__card:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}
#app .request .request__sidebar__footer {
  z-index: 99;
}
.undo_request_step {
  cursor: pointer;
}
button#form__Cliente_SceltaCliente {
  width: 100%;
}
.request__sidebar {
  background-color: #f1ece3;
}
.request__sidebar .ui.image.header {
  margin-left: 0.5rem;
}
.request__sidebar__footer {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
}
.ui.card.request__sidebar__card {
  margin: auto;
  border-radius: 8px;
  margin-bottom: 2rem;
}
.ui.card.request__sidebar__card .content.request__sidebar__card__content {
  display: flex;
  justify-content: space-between;
}
.ui.card.request__sidebar__card .content.request__sidebar__card__content.ui.card.request__sidebar__card .content.request__sidebar__card__content--tutorial {
  flex-direction: column;
}
.ui.card.request__sidebar__card .request__sidebar__card__button {
  padding: 0.8rem !important;
  background-color: #8098b2;
  border-radius: 30px;
  margin-left: 0.5rem;
}
.ui.card.request__sidebar__card .request__sidebar__card__button .plus.icon {
  color: #ffffff;
}
.ui.card.request__maintenance__card {
  position: relative;
  border-radius: 8px;
  margin: 4rem 0 0 0;
  width: 100%;
  max-width: 450px;
  min-height: unset;
}
.ui.card.request__maintenance__card .content.request__maintenance__card__content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.ui.card.request__maintenance__card .content.request__maintenance__card__content::after {
  position: absolute;
}
.ui.card.request__maintenance__card .request__maintenance__card__button {
  padding: 0.8rem !important;
  background-color: #8098b2;
  border-radius: 30px;
  margin-left: 0.5rem;
}
.ui.card.request__maintenance__card .request__maintenance__card__button .plus.icon {
  color: #ffffff;
}
.changing-skin {
  position: relative;
  padding: 4rem 4rem 6rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.changing-skin > .title {
  margin-bottom: 3rem;
  max-width: 40rem;
  text-align: center;
}
.changing-skin > .changing-skin-animation {
  max-width: 400px;
}
.ui.dividing.header[class$='Header'] {
  margin: 0;
  padding: 2rem;
  border-color: #C4C4C4;
}
@media screen and (max-width: 48rem) {
  .ui.dividing.header[class$='Header'] {
    padding: 1rem;
  }
}
.ui.dividing.header[class$='Header'] .ui.grid {
  margin: 0;
  align-items: center;
}
.ui.dividing.header[class$='Header'] .ui.grid .column {
  padding: 0;
}
.ui.dividing.header[class$='Header'] .ui.grid .column:last-child {
  flex: 1;
}
.ui.dividing.header[class$='Header'] .ui.grid .column.three.wide {
  width: 18.5% !important;
}
@media screen and (max-width: 1000px) {
  .ui.dividing.header[class$='Header'] .ui.grid .column.three.wide {
    width: 25% !important;
  }
}
@media screen and (max-width: 800px) {
  .ui.dividing.header[class$='Header'] .ui.grid .column.three.wide {
    width: 100% !important;
  }
}
.ui.dividing.header[class$='Header'] .ui.grid .column .ui.form {
  flex: 1;
}
@media screen and (max-width: 800px) {
  .ui.dividing.header[class$='Header'] .ui.grid .column {
    width: 100% !important;
    padding-bottom: 1rem;
  }
}
.ui.dividing.header[class$='Header'] h1 {
  font-size: 2rem;
  font-weight: 500;
  margin-right: 2rem;
}
@media screen and (max-width: 48rem) {
  .ui.dividing.header[class$='Header'] h1 {
    font-size: 1.75rem;
  }
}
.ui.dividing.header[class$='Header'] .padded.grid {
  width: 100%;
}
.ui.dividing.header[class$='Header'] .padded.grid .row {
  padding: 0;
  align-items: center;
}
.ui.dividing.header[class$='Header'] .padded.grid .row .column {
  min-width: 15rem;
}
.ui.dividing.header[class$='Header'] .padded.grid .row .column.voucher__buttons {
  min-width: unset;
  width: fit-content !important;
  margin-right: 30px;
}
.ui.dividing.header[class$='Header'] .padded.grid .row .column.generate-voucher,
.ui.dividing.header[class$='Header'] .padded.grid .row .column.add-agent {
  margin-left: auto;
  margin-right: 0;
  justify-content: flex-end;
}
.ui.dividing.header[class$='Header'] .padded.grid .row .column.add-agent {
  display: flex;
}
@media screen and (max-width: 800px) {
  .ui.dividing.header[class$='Header'] .padded.grid .row .column.add-agent {
    justify-content: flex-start;
    padding-bottom: 0;
  }
}
.ui.dividing.header[class$='Header'] .padded.grid .row .column.agentSearch {
  min-width: 20rem;
}
.ui.dividing.header[class$='Header'] .padded.grid .row .field {
  margin: 0 1.5rem 0 0;
  display: flex;
}
@media screen and (max-width: 1400px) {
  .ui.dividing.header[class$='Header'] .padded.grid .row .column.generate-voucher {
    margin-top: 10px;
    margin-right: auto;
    margin-left: 0;
  }
}
.ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'] {
  display: flex;
  position: relative;
}
.ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'] .button--underline {
  max-width: fit-content;
}
@media screen and (max-width: 1580px) {
  .ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'] {
    width: 100% !important;
    margin-top: 10px;
  }
  .ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'].customerReq {
    margin-top: 0;
  }
  .ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'].customerReq .ui.buttons {
    margin-top: 0;
  }
  .ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'] .column.generate-voucher {
    margin-top: 10px;
  }
}
.ui.dividing.header[class$='Header'] .padded.grid [class*='buttons'] > .button--primary {
  margin-right: 1.5rem;
}
#app .register__button--submit {
  margin-top: 1em;
}
#app .register__button--to-dashboard {
  margin-top: 1em;
}
#app .register__email-icon {
  display: flex;
  justify-content: center;
}
#app .password-recovery__button--submit {
  margin-top: 1em;
}
#app .password-recovery__button--to-dashboard {
  margin-top: 1em;
}
#app .password-recovery__email-icon {
  display: flex;
  justify-content: center;
}
#app .password-reset__button--submit {
  margin-top: 1em;
}
#app .password-reset__button--to-dashboard {
  margin-top: 1em;
}
#app .password-reset__email-icon {
  display: flex;
  justify-content: center;
}
div.homepage-container div.ui.center.aligned.segment {
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
}
div.homepage-container div.ui.center.aligned.segment .homeHeader {
  max-width: 50rem;
  margin: 0 auto;
  padding: 10vh 0;
}
div.homepage-container div.ui.center.aligned.segment .homeHeader h1 {
  font-size: 2.5rem;
  margin-bottom: 0.75rem;
}
div.homepage-container div.ui.center.aligned.segment .homeHeader p {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles {
  padding: 0 2rem;
  max-width: 80rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile {
  margin: 0 !important;
  width: 32%;
  padding: 2.5rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: none;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile .tileIcon {
  height: 1.75rem;
  width: 1.75rem;
  margin: 0 auto;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile .tileIcon[data-icon='calendar'] {
  background: url('/src/assets/images/icon/calendar.svg') no-repeat center center;
  background-size: contain;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile .tileIcon[data-icon='orders'] {
  background: url('/src/assets/images/icon/checklist.svg') no-repeat center center;
  background-size: contain;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile .tileIcon[data-icon='document'] {
  background: url('/src/assets/images/icon/document.svg') no-repeat center center;
  background-size: contain;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile .tileTitle {
  font-size: 1.5rem;
  margin: 1rem 0;
}
div.homepage-container div.ui.center.aligned.segment .homeTiles .tile .tileDescription {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
div.homepage-container div.ui.center.aligned.segment .homeMessage {
  font-size: 0.875rem;
  font-weight: 500;
  padding-top: 4rem;
}
div.homepage-container div.ui.center.aligned.segment .homeMessage a {
  font-weight: 700;
  text-decoration: underline;
}
#app .customer-container .customerSectionBody {
  max-width: 90rem;
  margin: 0 auto;
  padding: 2.5rem 2.5rem 4rem;
}
@media (max-width: 105rem) {
  #app .customer-container .customerSectionBody {
    max-width: 70rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .customer-container .customerSectionBody {
    padding: 2.5rem 1rem 4rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .customer-container .customerSectionBody .contentGrid {
    overflow-x: scroll;
    padding-bottom: 2rem;
  }
}
#app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}
@media screen and (max-width: 48rem) {
  #app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons {
    display: flex;
    flex-direction: column;
  }
}
#app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons button {
  min-height: 9rem;
  padding: 1.125rem 2rem !important;
  background: transparent;
  border: 1px solid #C4C4C4;
  border-radius: 0.15rem;
  font-size: 1.125rem !important;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 48rem) {
  #app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons button {
    margin-bottom: 1rem;
  }
}
#app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons button i.icon {
  margin: 0;
  font-size: 2rem;
}
#app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons button.primary {
  border: 2px solid #8098B2;
  color: #000;
  font-weight: 600;
}
#app .customer-container #customerProfileForm .ui.grid.company-type .ui.buttons button.primary i.icon {
  color: #8098B2;
}
#app .customer-container #customerProfileForm .ui.grid.company-type .company-type-profile.ui.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}
@media screen and (max-width: 600px) {
  #app .customer-container #customerProfileForm .ui.grid.company-type .company-type-profile.ui.buttons {
    grid-template-columns: repeat(1, 1fr);
  }
}
#app .customer-container .customerSectionActions {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
body.customer-list table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
body.customer-list table.ui.table thead {
  display: none;
}
body.customer-list table.ui.table tbody td {
  border: 0;
}
body.customer-list table.ui.table tbody td:first-child {
  color: #8098B2;
  font-size: 1.5rem;
  font-weight: 700;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
body.customer-list table.ui.table tbody td:last-child {
  text-align: right;
  font-size: 0.875rem;
  font-weight: 600;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
body.customer-list table.ui.table tbody td:last-child a {
  text-decoration: underline;
  color: #000;
}
body.customer-requests table.ui.table tbody td {
  padding: 0.75rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  color: #3B4148;
  border: 0;
  border-bottom: 1px solid #F9F9F9;
  position: relative;
}
@media screen and (max-width: 48rem) {
  body.customer-requests table.ui.table tbody td {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}
body.customer-requests table.ui.table tbody td a {
  font-size: 0.875rem;
  font-weight: 600;
  color: #8098B2;
  text-decoration: underline;
}
body.customer-requests table.ui.table tbody td:nth-child(4):before,
body.customer-requests table.ui.table tbody td:nth-child(5):before,
body.customer-requests table.ui.table tbody td:nth-child(6):before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 1.25rem;
  background: #C4C4C4;
}
#app .request #form__Piano .ui.card {
  width: 26rem;
  max-width: 50%;
  margin: 0 1.25rem;
  border: 2px solid #8098B2;
  border-radius: 2rem;
  box-shadow: none;
}
#app .request #form__Piano .ui.card.request__plan_card_selected {
  outline: 3px solid #8098B2;
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .ui.card {
    max-width: 85vw;
  }
  #app .request #form__Piano .ui.card:first-child {
    margin-bottom: 5rem;
  }
}
#app .request #form__Piano .ui.card > .content {
  border: 0;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .ui.card > .content {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
#app .request #form__Piano .ui.card .content__header {
  padding-top: 2.5rem;
  padding-bottom: 0.75rem;
}
#app .request #form__Piano .ui.card .content__header .header {
  font-size: 2.25rem;
  font-weight: 600;
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .ui.card .content__header .header {
    font-size: 2rem;
  }
}
#app .request #form__Piano .ui.card .content__header .content__image-wrapper {
  position: absolute;
  width: 7rem;
  height: 7rem;
  right: 2.5rem;
  top: -2.5rem;
  filter: drop-shadow(0.25rem 0.25rem 0.75rem rgba(61, 61, 61, 0.24));
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .ui.card .content__header .content__image-wrapper {
    width: 6rem;
    height: 6rem;
    top: -3.5rem;
    right: 1rem;
  }
}
#app .request #form__Piano .ui.card .content__header .content__image-wrapper img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
#app .request #form__Piano .ui.card .content__price {
  font-size: 1.125rem;
  font-weight: 600;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .ui.card .content__price {
    padding-top: 1.5rem;
  }
}
#app .request #form__Piano .ui.card .content__price strong {
  font-size: 2.25rem;
}
#app .request #form__Piano .ui.card .content__features .item {
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;
}
#app .request #form__Piano .ui.card .content__features .item i:before {
  content: '';
  display: inline-block;
  width: 1rem;
  height: 0.875rem;
}
#app .request #form__Piano .ui.card .content__features .item i.green.check:before {
  background: url('/src/assets/images/icon/check.svg') no-repeat center center !important;
  background-size: contain;
}
#app .request #form__Piano .ui.card .content__features .item i.red.cancel:before {
  background: url('/src/assets/images/icon/close-red.svg') no-repeat center center !important;
  background-size: contain;
}
#app .request #form__Piano .ui.card .content__features .item .content {
  font-weight: 500;
  padding-left: 0.75rem;
  line-height: 1.5;
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .ui.card .content__features .item .content {
    text-align: left;
  }
}
#app .request #form__Piano .ui.card .content__features .item i.red.cancel + .content {
  color: #9B9B9B;
  text-decoration: line-through;
}
#app .request #form__Piano .ui.card .content__buttons {
  padding-bottom: 2.5rem;
}
#app .request #form__Piano .ui.card .content__buttons button {
  width: 100%;
}
#app .request #form__Piano .request__invoice-wrapper {
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
  padding: 3rem 0;
}
#app .request #form__Piano .request__invoice-wrapper .request__invoice-checkbox {
  text-align: center;
  margin-bottom: 2.5rem;
}
#app .request #form__Piano .request__invoice-wrapper .column {
  padding: 0 1rem;
}
#app .request #form__Piano .request__invoice-wrapper > .row.three {
  display: flex;
  flex-wrap: wrap;
}
#app .request #form__Piano .request__invoice-wrapper > .row.three > .column {
  width: calc(100% / 3) !important;
}
@media screen and (max-width: 48rem) {
  #app .request #form__Piano .request__invoice-wrapper > .row.three > .column {
    width: 100% !important;
  }
}
#app .request #form__Pre_Pagamento .request__voucher-code {
  width: 70% !important;
}
#app .request .balance_calcolo_dimesionale_azienda__content table tbody td:last-child {
  display: flex;
}
.dashboard-container .dashboard__content .dashboardHeader {
  padding: 2rem;
  display: flex;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid {
  margin: 0;
  width: 100%;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column {
  padding: 0;
  display: flex;
  align-items: center;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column i {
  color: #8098b2;
  margin-right: 1rem;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column h1 {
  font-weight: 500;
  margin-top: 0;
  line-height: 1em;
}
.dashboard-container .dashboard__content .dashboardHeader > .ui.grid .column:first-child {
  width: 35vw;
}
.dashboard-container .dashboard__content .dashboardBody {
  padding: 2em 4em;
}
.dashboard-container .dashboard__content .dashboardBody .row {
  display: flex;
  height: 500px;
}
.dashboard-container .dashboard__content .dashboardBody .row:not(:first-child) {
  margin-top: 3em;
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody .row {
    flex-direction: column;
    height: fit-content;
  }
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody .row .column {
    margin-top: 2rem;
  }
}
.dashboard-container .dashboard__content .dashboardBody .row:last-child {
  display: flex;
  justify-content: space-between;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid {
  display: flex;
  flex-flow: row wrap;
  width: 30%;
  flex: 0 0 30%;
  justify-content: space-between;
  height: 100%;
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody .statsGrid {
    width: 100%;
  }
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid:first-child > .stat:nth-child(2),
.dashboard-container .dashboard__content .dashboardBody .statsGrid:first-child > .stat:nth-child(3) {
  background: #f9f9f9;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid:first-child > .stat:nth-child(2) i,
.dashboard-container .dashboard__content .dashboardBody .statsGrid:first-child > .stat:nth-child(3) i {
  background: white;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid:last-child > .stat:nth-child(1),
.dashboard-container .dashboard__content .dashboardBody .statsGrid:last-child > .stat:nth-child(4) {
  background: #f9f9f9;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid:last-child > .stat:nth-child(1) i,
.dashboard-container .dashboard__content .dashboardBody .statsGrid:last-child > .stat:nth-child(4) i {
  background: white;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat {
  width: 45%;
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  transition: transform 0.3s linear;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(1),
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:nth-child(2) {
  margin-bottom: 2rem;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div {
  margin: 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > .statName {
  color: #8098b2;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > i {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  border-radius: 50%;
  color: #8098b2;
  margin: 0 0 15px 0;
  padding: 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > i::before {
  font-size: 1.5rem;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div > img {
  width: 56px;
  height: 56px;
  margin: 0 0 15px 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > div h1 {
  font-size: 48px;
  line-height: 66px;
  margin: 5px 0;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat > .untilToday {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
.dashboard-container .dashboard__content .dashboardBody .statsGrid > .stat:hover {
  box-shadow: 4px 4px 8px rgba(7, 7, 7, 0.22);
  transform: scale(105%);
  transition: transform 0.5s linear;
}
.dashboard-container .dashboard__content .dashboardBody .chart {
  width: 70%;
  padding: 0;
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody .chart {
    width: 100%;
  }
}
.dashboard-container .dashboard__content .dashboardBody .chart-half {
  width: calc(50% - 1rem);
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody .chart-half {
    width: 100%;
  }
}
.dashboard-container .dashboard__content .dashboardBody #reqByCompanyType,
.dashboard-container .dashboard__content .dashboardBody #reqByDoc,
.dashboard-container .dashboard__content .dashboardBody #reqMonthlyTrend,
.dashboard-container .dashboard__content .dashboardBody #regMonthlyTrend {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody #reqByCompanyType,
  .dashboard-container .dashboard__content .dashboardBody #reqByDoc,
  .dashboard-container .dashboard__content .dashboardBody #reqMonthlyTrend,
  .dashboard-container .dashboard__content .dashboardBody #regMonthlyTrend {
    height: 500px;
    padding: 0;
  }
}
.dashboard-container .dashboard__content .dashboardBody #reqByCompanyType > * canvas,
.dashboard-container .dashboard__content .dashboardBody #reqByDoc > * canvas,
.dashboard-container .dashboard__content .dashboardBody #reqMonthlyTrend > * canvas,
.dashboard-container .dashboard__content .dashboardBody #regMonthlyTrend > * canvas {
  font-family: inherit;
  border-radius: 4px;
}
.dashboard-container .dashboard__content .dashboardBody #regMonthlyTrend {
  padding-right: 2rem;
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody #regMonthlyTrend {
    padding-right: 0;
  }
}
.dashboard-container .dashboard__content .dashboardBody #reqMonthlyTrend {
  padding-left: 2rem;
}
@media screen and (max-width: 1400px) {
  .dashboard-container .dashboard__content .dashboardBody #reqMonthlyTrend {
    padding-left: 0;
  }
}
@media screen and (max-width: 1500px) {
  .dashboard-container .dashboard__content .dashboardHeader {
    padding: 1rem;
  }
}
#app .agent-container .ui.grid .column.columnProfile {
  justify-content: flex-start;
}
#app .agentSectionBody {
  max-width: 90rem;
  margin: 0 auto;
  padding: 2.5rem 2.5rem 4rem;
}
@media (max-width: 105rem) {
  #app .agentSectionBody {
    max-width: 70rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .agentSectionBody {
    padding: 2.5rem 1rem 4rem;
  }
}
#app .agentSectionBody .contentGrid table tr td .ctas {
  display: flex;
  justify-content: center;
}
#app .agentSectionBody .contentGrid table tr td .ctas > a:not(:last-child) {
  margin-right: 10px;
}
@media screen and (max-width: 48rem) {
  #app .agentSectionBody .contentGrid {
    overflow-x: scroll;
    padding-bottom: 2rem;
  }
}
#app #agentProfileForm .field {
  width: 100%;
}
#app #agentProfileForm .ui.grid.company-type .ui.buttons {
  flex: 0 0 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
}
@media screen and (max-width: 48rem) {
  #app #agentProfileForm .ui.grid.company-type .ui.buttons {
    grid-template-columns: repeat(1, 1fr);
  }
}
#app #agentProfileForm .ui.grid.company-type .ui.buttons button {
  padding: 1.125rem 2rem !important;
  background: transparent;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  font-size: 1.05rem !important;
  font-weight: 500;
  line-height: 1.2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
@media screen and (max-width: 48rem) {
  #app #agentProfileForm .ui.grid.company-type .ui.buttons button {
    margin-bottom: 1rem;
  }
}
#app #agentProfileForm .ui.grid.company-type .ui.buttons button i.icon {
  margin: 0 0 1rem 0;
  font-size: 2rem;
}
#app #agentProfileForm .ui.grid.company-type .ui.buttons button.primary {
  border: 2px solid #8098B2;
  color: #000;
  font-weight: 600;
  background-color: rgba(128, 152, 178, 0.05);
}
#app #agentProfileForm .ui.grid.company-type .ui.buttons button.primary i.icon {
  color: #8098B2;
}
#app .agentSectionActions {
  padding-top: 1.5rem;
  float: right;
}
#agentProfileForm .ui.buttons button:first-child {
  border: 0px solid transparent;
}
#agentProfileForm .ui.buttons.create .button {
  border-radius: 50rem;
}
#agentProfileForm .ui.grid.company-type .row:last-child .column {
  display: flex;
  justify-content: flex-end;
}
#agentProfileForm .ui.grid.company-type .row:last-child .column button {
  min-width: 8rem;
  display: flex;
  justify-content: center;
}
#app .voucher-container .padded.grid .row {
  padding: 0;
  align-items: center;
}
#app .voucher-container .padded.grid .row .column:nth-child(3) .field {
  font-weight: normal;
}
#app .voucher-container .padded.grid .row .field {
  margin: 0 1.5rem 0 0;
  display: flex;
}
#app .voucher-container .padded.grid .row .field > * {
  width: 100%;
}
#app .voucher-container .padded.grid .customer__buttons {
  display: flex;
}
#app .voucher-container .padded.grid .customer__buttons > .button--primary {
  margin-right: 1.5rem;
}
#app .voucherSectionBody {
  max-width: 90rem;
  margin: 0 auto;
  padding: 2.5rem 2.5rem 4rem;
}
@media screen and (max-width: 48rem) {
  #app .voucherSectionBody {
    padding: 2.5rem 1rem 4rem;
  }
}
@media screen and (max-width: 48rem) {
  #app .voucherSectionBody .contentGrid {
    overflow-x: scroll;
    padding-bottom: 2rem;
  }
}
#form__generateVoucher .field.discountPercentage .ui.selection.dropdown > .text {
  opacity: 0;
}
#form__generateVoucher .field.discountPercentage.filled .ui.selection.dropdown > .text {
  opacity: 1;
}
#form__generateVoucher .field.discountPercentage.error .ui.selection.dropdown > .text {
  opacity: 0;
}
body.voucher-list table.ui.table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
body.voucher-list table.ui.table thead {
  display: none;
}
body.voucher-list table.ui.table tbody td {
  border: 0;
}
body.voucher-list table.ui.table tbody td:first-child {
  color: #8098B2;
  font-size: 1.5rem;
  font-weight: 700;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
body.voucher-list table.ui.table tbody td:last-child {
  text-align: right;
  font-size: 0.875rem;
  font-weight: 600;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
body.voucher-list table.ui.table tbody td:last-child a {
  text-decoration: underline;
  color: #000;
}
#app .request .form.ui.form.form__Bilancio .balance__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
#app .request .form.ui.form.form__Bilancio .balance__header h1.balance__header__title {
  font-weight: 500;
  font-size: 1.75rem;
  margin: 1.5rem 0rem;
}
#app .request .form.ui.form.form__Bilancio .balance__header h1.balance__header__title span.title__bold {
  font-weight: 700;
}
#app .request .form.ui.form.form__Bilancio .balance__header h4.balance__header__subtitle {
  font-weight: 500;
  margin: 0 0 1rem 0;
}
#app .request .form.ui.form.form__Bilancio .row.checkbox,
#app .request .form.ui.form.form__Bilancio .row.single-input,
#app .request .form.ui.form.form__Bilancio .row.modify--balances {
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
}
#app .request .form.ui.form.form__Bilancio .row.checkbox > .column,
#app .request .form.ui.form.form__Bilancio .row.single-input > .column,
#app .request .form.ui.form.form__Bilancio .row.modify--balances > .column {
  width: 80%;
  display: block;
  position: relative;
}
@media screen and (max-width: 48rem) {
  #app .request .form.ui.form.form__Bilancio .row.checkbox > .column,
  #app .request .form.ui.form.form__Bilancio .row.single-input > .column,
  #app .request .form.ui.form.form__Bilancio .row.modify--balances > .column {
    width: 100%;
  }
}
#app .request .form.ui.form.form__Bilancio .row.modify--balances {
  padding: 1.2rem 0;
}
#app .request .form.ui.form.form__Bilancio .row.modify--balances > .column {
  display: flex;
  justify-content: flex-end;
}
#app .request .form.ui.form.form__Bilancio .row.modify--balances > .column button:not(:last-child) {
  margin-right: 0.5rem;
}
#app .request .form.ui.form.form__Bilancio .row.checkbox {
  padding: 1.5rem 0;
}
#app .request .form.ui.form.form__Bilancio .row.checkbox > .field {
  padding: 0 1rem;
  margin: 0 0 0.7rem 0;
}
#app .request .form.ui.form.form__Bilancio .row.checkbox span {
  padding: 1rem;
  position: relative;
}
#app .request .form.ui.form.form__Bilancio .row.single-input {
  padding: 0 0 1.5rem 0;
}
#app .request .form.ui.form.form__Bilancio .row.single-input label {
  position: relative;
  padding-right: 20px;
}
#app .request .form.ui.form.form__Bilancio .row.single-input label > img.ui.image {
  position: absolute;
  top: 0;
  right: 0;
}
#app .request .form.ui.form.form__Bilancio div.balance-required {
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
#app .request .form.ui.form.form__Bilancio div.balance-required > span {
  margin: 0;
  color: #EB5757;
}
#app .request .form.ui.form.form__Bilancio .balance__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#app .request .form.ui.form.form__Bilancio .balance__content:not(:last-child) {
  margin-bottom: 0.8rem;
}
#app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid {
  width: 80%;
}
@media screen and (max-width: 48rem) {
  #app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid {
    width: 100%;
  }
}
#app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid .row > .column {
  padding-right: 0;
  padding-left: 0;
}
#app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid .row > .column:not(:first-child) {
  margin-left: 1rem;
}
@media screen and (max-width: 550px) {
  #app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid .row > .column:not(:first-child) {
    margin: 0;
  }
}
#app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid [class*='two column'].row > .column {
  width: calc(50% - 0.5rem) !important;
}
@media screen and (max-width: 550px) {
  #app .request .form.ui.form.form__Bilancio .balance__content div.ui.grid [class*='two column'].row > .column {
    width: 100% !important;
  }
}
#app .request .form.ui.form.form__Bilancio .balance__content div.field > label {
  font-weight: 400;
  font-size: 0.875rem;
}
#app .request .form.ui.form.form__Bilancio .balance__content div.column > img.ui.image {
  position: absolute;
  top: 0.5rem;
  right: 0;
}
#app .request .form.ui.form.form__Bilancio .balance__content input:read-only {
  opacity: 0.5;
}
#app .request .form.ui.form.form__Bilancio .balance__content .balance__information__container {
  margin: auto;
  padding-bottom: 1rem;
}
#app .request .form.ui.form.form__Bilancio .balance__content .balance__information__subcontainer {
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1.5rem;
  background: #f9f9f9;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
}
#app .request .form.ui.form.form__Bilancio .balance__content .balance__information__image {
  width: 1.5rem;
  margin-right: 1rem;
}
#app .request .form.ui.form.form__Bilancio .balance__content .balance__information__text {
  color: #070707;
}
#app .request .form.ui.form.form__Bilancio .balance__content .balance__information__text__bold {
  text-decoration: underline;
  color: #8098b2;
  font-weight: 600;
}
.form__BalanceCalcoloDimensionaleAzienda .balance_calcolo_dimesionale_azienda__content div.column > img.ui.image {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.form.ui.form.form__Investimento .investment__header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.form.ui.form.form__Investimento .investment__header h1.investment__header__title {
  font-weight: 500;
  font-size: 1.75rem;
  margin: 1.5rem 0rem;
}
.form.ui.form.form__Investimento .investment__header h1.investment__header__title span.title__bold {
  font-weight: 700;
}
.form.ui.form.form__Investimento .investment__header p.investment__header__subtitle {
  font-weight: 500;
  margin: 0 0 1rem 0;
}
.form.ui.form.form__Investimento .investment__header p.investment__header__subtitle span.subtitle__bold {
  font-weight: 700;
}
.form.ui.form.form__Investimento .investment__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form.ui.form.form__Investimento .investment__content div.column > img.ui.image {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.form.ui.form.form__Investimento .investment__content__grid {
  width: 80%;
}
@media screen and (max-width: 990px) {
  .form.ui.form.form__Investimento .investment__content__grid {
    width: 100%;
  }
}
.form.ui.form.form__Investimento .investment__content__grid div.field > label {
  font-weight: 400;
  font-size: 0.875rem;
}
.form.ui.form.form__Investimento .investment__content__grid__textarea .field {
  width: 100%;
}
.form.ui.form.form__Investimento .investment__content__grid__textarea .field .ui.input {
  padding-right: 1rem;
}
.form.ui.form.form__Investimento .investment__content__grid__textarea .field .ui.input > textarea {
  min-height: 75px;
  height: 75px;
  margin-left: 1rem;
}
.form.ui.form.form__Investimento .investment__content__grid__textarea > span {
  margin-left: 1rem;
}
.form.ui.form.form__Investimento .investment__content__grid__dateWarning {
  color: #239f76;
  font-size: 12px;
  font-weight: 600;
}
.form.ui.form.form__Investimento .investment__content .field.label-on-top {
  position: relative;
  width: 100%;
}
.form.ui.form.form__Investimento .investment__content .field.label-on-top .ui.toggle.checkbox {
  position: relative;
  width: 100%;
}
.form.ui.form.form__Investimento .investment__content .field.label-on-top .ui.toggle.checkbox label {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 4.6rem;
  margin-top: 0;
  height: fit-content;
  line-height: 21px;
}
.form.ui.form.form__Investimento .investment__content .field.label-on-top .ui.toggle.checkbox label:before {
  top: 0;
}
.form.ui.form.form__Investimento .investment__content .field.label-on-top .ui.toggle.checkbox label:after {
  top: 0.21rem;
}
.form.ui.form.form__Investimento .investment__content .row.investment__content__grid__textarea span {
  position: relative;
  top: 0;
}
.step__finalita__container,
.step__cp__container {
  margin-top: 1.5rem !important;
  margin-left: 10rem !important;
}
.step__finalita__options,
.step__cp__options {
  width: 100%;
}
.step__finalita__option,
.step__cp__option {
  padding: 10px;
  display: flex;
}
.step__finalita__tooltip,
.step__cp__tooltip {
  margin-left: 10px;
  flex: 0 0 18px;
  min-width: 18px;
}
.step__finalita__input .ui.checkbox,
.step__cp__input .ui.checkbox {
  width: 1.5rem;
  height: 1.5rem;
}
.step__finalita__input .ui.checkbox input[type='checkbox'],
.step__cp__input .ui.checkbox input[type='checkbox'] {
  width: 1.5rem;
  height: 1.5rem;
}
.step__finalita__input .ui.checkbox label:before,
.step__cp__input .ui.checkbox label:before,
.step__finalita__input .ui.checkbox label:after,
.step__cp__input .ui.checkbox label:after {
  height: 1.5rem;
  width: 1.5rem;
}
.step__finalita__input .ui.checkbox label:before,
.step__cp__input .ui.checkbox label:before {
  border-color: #9B9B9B;
}
.step__finalita__input .ui.checkbox.checked label:before,
.step__cp__input .ui.checkbox.checked label:before,
.step__finalita__input .ui.checkbox input:checked ~ label:before,
.step__cp__input .ui.checkbox input:checked ~ label:before {
  background: #239F76;
  border-color: #239F76;
}
.step__finalita__input .ui.checkbox.checked label:after,
.step__cp__input .ui.checkbox.checked label:after,
.step__finalita__input .ui.checkbox input:checked ~ label:after,
.step__cp__input .ui.checkbox input:checked ~ label:after {
  content: '';
  background: url('../../assets/images/icon/done.svg') no-repeat center center;
  background-size: 80%;
}
.step__finalita__input .ui.checkbox.disabled,
.step__cp__input .ui.checkbox.disabled {
  opacity: 0.7;
  background: #C4C4C4;
  border-radius: 0.19rem;
}
#form__Finalita .ui.grid,
#form__Cliente_CassaProfessionale .ui.grid {
  padding-left: 2rem;
  padding-right: 2rem;
}
#form__Finalita .ui.grid .row:first-child .titleTooltip,
#form__Cliente_CassaProfessionale .ui.grid .row:first-child .titleTooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#form__Finalita .ui.grid .row:first-child h3.ui.header,
#form__Cliente_CassaProfessionale .ui.grid .row:first-child h3.ui.header {
  position: relative;
  flex: 0 0 fit-content;
  width: fit-content;
  white-space: normal;
  word-wrap: break-word;
  max-width: calc(100% - 30px);
}
#form__Finalita .ui.grid .row:first-child .ui.image,
#form__Cliente_CassaProfessionale .ui.grid .row:first-child .ui.image {
  position: relative;
  width: 20px;
  flex: 0 0 20px;
  margin-left: 10px;
  margin-top: 0;
}
#form__Finalita .ui.grid .step__finalita__label,
#form__Cliente_CassaProfessionale .ui.grid .step__finalita__label,
#form__Finalita .ui.grid .step__cp__label,
#form__Cliente_CassaProfessionale .ui.grid .step__cp__label {
  position: relative;
}
#form__Finalita .ui.grid .step__finalita__label .ui.toggle.checkbox,
#form__Cliente_CassaProfessionale .ui.grid .step__finalita__label .ui.toggle.checkbox,
#form__Finalita .ui.grid .step__cp__label .ui.toggle.checkbox,
#form__Cliente_CassaProfessionale .ui.grid .step__cp__label .ui.toggle.checkbox {
  margin-top: 1rem;
}
#form__Finalita .ui.grid .step__finalita__label .ui.toggle.checkbox label::before,
#form__Cliente_CassaProfessionale .ui.grid .step__finalita__label .ui.toggle.checkbox label::before,
#form__Finalita .ui.grid .step__cp__label .ui.toggle.checkbox label::before,
#form__Cliente_CassaProfessionale .ui.grid .step__cp__label .ui.toggle.checkbox label::before {
  width: 4rem;
  height: 2rem;
  top: -0.25rem;
}
#form__Finalita .ui.grid .step__finalita__label .ui.toggle.checkbox label::after,
#form__Cliente_CassaProfessionale .ui.grid .step__finalita__label .ui.toggle.checkbox label::after,
#form__Finalita .ui.grid .step__cp__label .ui.toggle.checkbox label::after,
#form__Cliente_CassaProfessionale .ui.grid .step__cp__label .ui.toggle.checkbox label::after {
  width: 1.625rem;
  height: 1.625rem;
  box-shadow: none;
  background: #fff;
  top: -0.05rem;
  left: 0.2rem;
}
#form__Finalita .ui.grid .step__finalita__label .ui.toggle.checkbox.checked label::before,
#form__Cliente_CassaProfessionale .ui.grid .step__finalita__label .ui.toggle.checkbox.checked label::before,
#form__Finalita .ui.grid .step__cp__label .ui.toggle.checkbox.checked label::before,
#form__Cliente_CassaProfessionale .ui.grid .step__cp__label .ui.toggle.checkbox.checked label::before {
  background-color: #239F76 !important;
}
#form__Finalita .ui.grid .step__finalita__label .ui.toggle.checkbox.checked label::after,
#form__Cliente_CassaProfessionale .ui.grid .step__finalita__label .ui.toggle.checkbox.checked label::after,
#form__Finalita .ui.grid .step__cp__label .ui.toggle.checkbox.checked label::after,
#form__Cliente_CassaProfessionale .ui.grid .step__cp__label .ui.toggle.checkbox.checked label::after {
  left: 2.2rem;
}
#form__Cliente_RichiestaPending .ui.grid .animation-container {
  width: 400px;
  padding-left: 0;
  padding-right: 0;
  height: fit-content;
}
#form__Cliente_RichiestaPending .ui.grid .animation-container > div {
  width: 100%;
  position: relative;
}
#form__Cliente_RichiestaPending .ui.grid .animation-container > div svg {
  position: relative;
}
#form__Cliente_RichiestaPending .ui.grid .loadingLineContainer {
  width: 90%;
  height: 1.5rem;
  border: 1px solid #4e4627;
  border-radius: 5px;
  padding: 2px;
  box-sizing: border-box;
}
#form__Cliente_RichiestaPending .ui.grid .loadingLineContainer > .loadingLine {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #a9a79f 0%, #f1ece3 100%);
  border-radius: 5px;
}
#form__Dichiarazioni .ui.grid div.row:first-child img.ui.image {
  width: 20px;
  position: absolute;
  top: 0;
  right: 1rem;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row {
  display: flex;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--checkbox {
  flex: 0 0 5rem;
  width: 5rem;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications {
  flex: 1;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications.disabled {
  opacity: 0.5;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications p {
  line-height: 1.8rem;
  font-size: 1rem;
  text-align: justify;
  text-justify: inter-word;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field {
  border: none;
  display: inline;
  width: fit-content;
  flex: 0 0 fit-content;
  height: 100%;
  margin: 0;
  font-size: 1rem;
  padding: 0;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input {
  border: none;
  width: fit-content;
  flex: 0 0 fit-content;
  min-width: 2rem;
  height: 100%;
  padding: 0;
  margin: 0;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input.error input {
  background: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input input {
  width: fit-content;
  flex: 0 0 fit-content;
  min-width: 2rem;
  padding: 0.2rem 0.5rem !important;
  line-height: 1.2rem;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input.decree-letter input {
  min-width: 2rem;
  width: 2rem;
  flex: 0 0 2rem;
  padding: 0.2rem 0.5rem 0.2rem 0.6rem !important;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input.reimbursementDate {
  margin: 0 3px;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input .MuiFormControl-root {
  width: fit-content;
  flex: 0 0 fit-content;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input .MuiFormControl-root .MuiInputBase-root {
  max-width: fit-content;
  border-radius: 0.25rem;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input .MuiFormControl-root input.MuiInputBase-input {
  min-width: 8rem;
  width: 8rem;
  flex: 0 0 8rem;
  height: auto;
  margin: 0;
}
#form__Dichiarazioni_De_Minimis .ui.grid .row .column.decl--specifications .field .ui.input .MuiFormControl-root .MuiIconButton-root {
  padding: 0;
  margin-right: 0.3rem;
}
#form__Verifica_GeneraDocumento .ui.grid .row:nth-child(2) .ui.image {
  width: 20px;
  position: absolute;
  top: 0;
  right: 1rem;
}
#form__Verifica_GeneraDocumento .ui.grid .column.document-column .disabled.field {
  opacity: 1;
}
#form__Verifica_GeneraDocumento .ui.grid .column.document-column .disabled.field .ui.disabled.dropdown {
  opacity: 1;
}
#form__Verifica_GeneraDocumento .ui.grid .column.document-column .disabled.field i {
  opacity: 0;
}
.pagamento-pending-container {
  padding: 2rem 0;
  width: 40vw;
  height: 100%;
  display: flex;
  align-items: center;
}
.pagamento-pending-container > div > .stripeCheckoutSectionBody {
  position: relative;
}
.pagamento-pending-container > div > .stripeCheckoutSectionBody .stripeCheckoutSectionResponse > .ui.button {
  background: #f1ece3;
  margin-top: 1rem;
}
.pagamento-pending-container > div > .stripeCheckoutSectionBody .stripeCheckoutSectionResponse > .ui.button:hover {
  background: #D8C9B0;
}
#form__Cliente_Riepilogo label.tooltip {
  display: flex;
}
#form__Cliente_Riepilogo label.tooltip img {
  margin-left: 10px;
}
@media screen and (max-width: 720px) {
  #form__Cliente_Riepilogo .ui.grid .column.row .column[class*='wide'] {
    width: 100% !important;
  }
}
#form__Cliente_Riepilogo .ui.grid .column.fraction-flag {
  height: 100%;
}
#form__Cliente_Riepilogo .ui.grid .column.fraction-flag .field {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#form__Cliente_Riepilogo .ui.grid .column.flooding-flag {
  height: 100%;
}
#form__Cliente_Riepilogo .ui.grid .column.flooding-flag .field {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
#form__Cliente_Riepilogo .ui.grid .column .ui.checkbox {
  position: relative;
}
#form__Cliente_Riepilogo .ui.grid .column .ui.checkbox input.hidden {
  position: absolute;
  top: 0;
  left: 0;
}
.ui.form#form__Finanziamento .finanziamento__header h1.finanziamento__header__title {
  width: auto;
}
.ui.form#form__Finanziamento .finanziamento__header h1.finanziamento__header__title strong {
  position: relative;
}
.ui.form#form__Finanziamento .finanziamento__header h1.finanziamento__header__title strong > img {
  position: absolute;
  top: 0;
  right: -2rem;
}
.ui.form#form__Finanziamento .column .disabled.field {
  opacity: 0.7;
}
.ui.modal.transition.visible.active h2.finanziamento:first-letter {
  text-transform: uppercase;
}
.appointment_modal_actions {
  display: flex;
  justify-content: flex-end;
}
.appointment_help {
  display: flex;
  height: 40px;
  font-size: 0.875rem;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}
.appointment_help img {
  display: inline-block;
  margin-right: 0.5rem;
}
.appointment_title {
  font-size: 1.125rem;
  font-weight: 500;
}
.appointment_legend {
  list-style: none;
  width: 20em;
  padding-left: 1rem;
  margin: 0.75rem 0;
}
.appointment_legend__box {
  width: 1em;
  height: 1em;
  border: 1px solid black;
  float: left;
}
.appointment_legend__box--busy {
  background-color: red;
}
.appointment_legend__box--available {
  background-color: #239F76;
}
.appointment_legend__box--not-available {
  background-color: grey;
}
.appointment_legend__box--selected {
  background-color: #396288;
}
.appointment_legend__color {
  font-weight: bold;
}
.appointment_legend li {
  margin-right: 10px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.appointment_legend li span {
  margin-left: 4px;
}
.fc-highlight {
  background-color: #396288 !important;
}
#form__Regimi .row:not(:first-child) {
  display: flex;
  justify-content: center;
}
.stripe-checkout-container {
  padding: 2rem 0;
}
.stripe-checkout-container .waiting {
  display: flex;
  justify-content: center;
}
.consultancy-invoice-modal {
  padding: 2rem 3rem;
  display: flex;
  direction: column;
}
.consultancy-invoice-modal .ui.grid {
  margin: 0;
}
.consultancy-invoice-modal .ui.grid > .row:last-child {
  margin-bottom: 15px;
}
.consultancy-invoice-modal .column {
  display: flex;
  justify-content: flex-end;
}
.consultancy-invoice-modal .column .ui.button {
  border-radius: 10rem;
  min-width: 120px;
}
