#app {
  .customer-container {
    .customerSectionBody {
      max-width: 90rem;
      margin: 0 auto;
      padding: 2.5rem 2.5rem 4rem;

      @media (max-width: @bp-2xl-mx) {
        max-width: 70rem;
      }

      @media screen and (max-width: @bp-md-mx) {
        padding: 2.5rem 1rem 4rem;
      }

      .contentGrid {
        @media screen and (max-width: @bp-md-mx) {
          overflow-x: scroll;
          padding-bottom: 2rem;
        }
      }
    }

    #customerProfileForm {
      .ui.grid.company-type {
        .ui.buttons {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 2rem;

          @media screen and (max-width: @bp-md-mx) {
            display: flex;
            flex-direction: column;
          }

          button {
            min-height: 9rem;
            padding: 1.125rem 2rem !important;
            background: transparent;
            border: 1px solid @c-grey-light;
            border-radius: 0.15rem;
            font-size: @font-18 !important;
            font-weight: 500;
            line-height: 1.2;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: @bp-md-mx) {
              margin-bottom: 1rem;
            }

            i.icon {
              margin: 0;
              font-size: @font-32;
            }

            &.primary {
              border: 2px solid @c-primary;
              color: #000;
              font-weight: 600;

              i.icon {
                color: @c-primary;
              }
            }
          }
        }
        .company-type-profile.ui.buttons {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 2rem;
          row-gap: 2rem;
          @media screen and (max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }

    .customerSectionActions {
      padding-top: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
