.step__finalita,
.step__cp {
  &__container {
    margin-top: 1.5rem !important;
    margin-left: 10rem !important;
  }

  &__options {
    width: 100%;
  }

  &__option {
    padding: 10px;
    display: flex;
  }

  &__tooltip {
    margin-left: 10px;
    flex: 0 0 18px;
    min-width: 18px;
  }

  &__input {
    .ui.checkbox {
      width: 1.5rem;
      height: 1.5rem;
      input[type='checkbox'] {
        width: 1.5rem;
        height: 1.5rem;
      }
      label {
        &:before,
        &:after {
          height: 1.5rem;
          width: 1.5rem;
        }
        &:before {
          border-color: @c-grey-mid;
        }
      }
      &.checked label:before,
      input:checked ~ label:before {
        background: @c-success;
        border-color: @c-success;
      }
      &.checked label:after,
      input:checked ~ label:after {
        content: '';
        background: url('../../../assets/images/icon/done.svg') no-repeat center
          center;
        background-size: 80%;
      }
      &.disabled {
        opacity: 0.7;
        background: @c-grey-light;
        border-radius: 0.19rem;
      }
    }
  }
}

#form__Finalita,
#form__Cliente_CassaProfessionale {
  .ui.grid {
    padding-left: 2rem;
    padding-right: 2rem;
    .row {
      &:first-child {
        .titleTooltip {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        h3.ui.header {
          position: relative;
          flex: 0 0 fit-content;
          width: fit-content;
          white-space: normal;
          word-wrap: break-word;
          max-width: calc(100% - 30px);
        }
        .ui.image {
          position: relative;
          width: 20px;
          flex: 0 0 20px;
          margin-left: 10px;
          margin-top: 0;
        }
      }
    }
    .step__finalita__label,
    .step__cp__label {
      position: relative;
      .ui.toggle.checkbox {
        margin-top: 1rem;
        label {
          &::before {
            width: 4rem;
            height: 2rem;
            top: -0.25rem;
          }
          &::after {
            width: 1.625rem;
            height: 1.625rem;
            box-shadow: none;
            background: #fff;
            top: -0.05rem;
            left: 0.2rem;
          }
        }
        &.checked {
          label {
            &::before {
              background-color: @c-success !important;
            }
            &::after {
              left: 2.2rem;
            }
          }
        }
      }
    }
  }
}
