form.ui.form {
  .grid > .row > .column {
    @media screen and (max-width: @bp-md-mx) {
      width: 100% !important;

      &:first-child {
        padding-bottom: 1rem;
      }
    }
  }
}

form.ui.form .field {
  position: relative;
  margin: 0 0 0.35rem;
  //width: 100%;
  // Label
  label {
    font-size: @font-14;
    font-weight: 400;
    margin-top: 0.5rem;

    a {
      color: @c-primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  input,
  .selection.dropdown {
    position: relative;
    border-color: @c-grey-light;
    border-radius: 0.125rem;
    padding: 0.65rem 2rem 0.65rem 1rem;
    font-weight: 500;
    width: 100%;
    flex: 0 0 100%;
    > .dropdown.icon {
      top: 50%;
      transform: translateY(-50%);
      margin: 0 -0.6875em 0 0;
    }

    &::placeholder {
      color: @c-grey-mid;
    }
  }

  // Checkbox
  .ui.checkbox:not(.toggle) {
    input {
      height: 1.5rem;
      width: 1.5rem;
    }

    label {
      text-align: left;
      margin: 0;
      padding-left: 2.5rem;

      &:before {
        height: 1.5rem;
        width: 1.5rem;
        border-color: @c-grey-mid;
        // top: -.25rem;
      }
    }

    &.checked {
      label:before {
        background: @c-success;
        border-color: @c-success;
      }

      label:after {
        content: '';
        height: 1.5rem;
        width: 1.5rem;
        background: url('../../images/icon/done.svg') no-repeat center center;
        background-size: 80%;
      }
    }
  }

  input.hidden {
    position: absolute;
    top: 0;
    left: 0;
  }

  // Toggle
  .ui.checkbox.toggle {
    label:before {
      width: 4rem;
      height: 2rem;
      top: -0.25rem;
    }

    label:after {
      width: 1.625rem;
      height: 1.625rem;
      box-shadow: none;
      background: #fff;
      top: -0.05rem;
      left: 0.2rem;
    }

    input:checked ~ label:before {
      background-color: @c-success !important;
    }

    input:checked ~ label:after {
      left: 2.2rem;
    }
  }

  // Message
  & + span {
    font-size: @font-12;
  }

  &.error + span {
    color: @c-error;
    position: absolute;
    top: 100%;
    line-height: 1em;
  }
}

span.error {
  color: #eb5757;
  font-size: @font-12;
  position: absolute;
  top: 100%;
  line-height: 1em;
  &.error-all {
    font-size: @font-14;
    line-height: 19px;
    display: block;
    position: relative;
    top: 0;
  }
}

// Dropdown

.ui.form .field.error .ui.dropdown {
  .text {
    background: transparent;
  }
  .selected.item {
    font-weight: bold;
  }
}

/* Datepicker */

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  border-radius: 0.125rem !important;
  border: 1px solid #c4c4c4 !important;
  display: block;

  .MuiInputBase-root {
    width: 100%;
  }

  .MuiInputBase-adornedEnd.MuiInputBase-formControl
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    width: 100%;
    position: relative;
    max-width: 100%;
    flex: unset;
  }

  div.ui.label.dropdown[role='listbox'],
  div:not(.select__input) > input[type='number'],
  div:not(.select__input) > input[type='text'],
  .select__control {
    border: 0 !important;
  }
}

// Error msg out-of-form

.ui.selection.dropdown {
  min-width: unset;
}

span.error {
  color: @c-error;
  position: absolute;
  top: 100%;
  line-height: 1em;
  font-size: @font-12;
}

.ui.form .field.error .input {
  color: @c-error;
}
