@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('/src/assets/fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}