#form__Cliente_RichiestaPending {
  .ui.grid {
    .animation-container {
      width: 400px;
      padding-left: 0;
      padding-right: 0;
      //background: #F1ECE3;
      height: fit-content;
      > div {
        width: 100%;
        position: relative;
        svg {
          position: relative;
        }
      }
    }
    .loadingLineContainer {
      width: 90%;
      height: 1.5rem;
      border: 1px solid #4e4627;
      border-radius: 5px;
      padding: 2px;
      box-sizing: border-box;
      > .loadingLine {
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(169,167,159,1) 0%, rgba(241,236,227,1) 100%);;
        border-radius: 5px;;
      }
    }
  }
}
