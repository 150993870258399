.consultancy-invoice-modal {
  padding: 2rem 3rem;
  display: flex;
  direction: column;
  .ui.grid {
    margin: 0;
    > .row {
      &:last-child {
        margin-bottom: 15px;
      }
    }
  }
  .column {
    display: flex;
    justify-content: flex-end;
    .ui.button {
      border-radius: 10rem;
      min-width: 120px;
    }
  }
}
