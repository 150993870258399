body.customer-requests {
	table.ui.table {
		tbody td {
			padding: .75rem 2rem;
			font-size: @font-18;
			font-weight: 500;
			text-align: center;
			color: @c-grey-norm;
			border: 0;
			border-bottom: 1px solid @c-grey-extra;
			position: relative;

			@media screen and (max-width: @bp-md-mx) {
        padding: .5rem 1rem;
				font-size: @font-14;
      }

			a {
				font-size: @font-14;
				font-weight: 600;
				color: @c-primary;
				text-decoration: underline;
			}

			&:nth-child(4):before,
			&:nth-child(5):before,
			&:nth-child(6):before {
				content: '';
				display: inline-block;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				height: 1.25rem;
				background: @c-grey-light;
			}
		}
	}
}