div.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: .5rem 0;
  text-align: center;
  background: @c-grey-norm;

  div.footer-text {
    font-size: @font-14;
    font-weight: 500;
    color: #FFF;
  }
}
