.pagamento-pending-container {
  padding: 2rem 0;
  width: 40vw;
  height: 100%;
  display: flex;
  align-items: center;
  > div {
    > .stripeCheckoutSectionBody {
      position: relative;
      .stripeCheckoutSectionResponse {
        >.ui.button {
          background: #f1ece3;
          margin-top: 1rem;
          &:hover {
            background: #D8C9B0;
          }
        }
      }
    }
  }
}
